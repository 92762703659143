import { ActionCreator } from 'redux';

import { EapAction } from 'types';
import { MemberSearchResult, LeavePeriod, ExceptionsObject } from 'interfaces';

import { Form165aData } from './form165aReducer';

export enum form165aActionTypes {
  SET_REQUEST_NUMBER_FORM_165A = 'SET_REQUEST_NUMBER_FORM_165A',
  CREATE_FORM_165A = 'CREATE_FORM_165A',
  CREATE_FORM_165A_IN_NEW_TAB = 'CREATE_FORM_165A_IN_NEW_TAB',
  GET_FORM_165A_REQUESTED = 'GET_FORM_165A_REQUESTED',
  GET_FORM_165A_SUCCESS = 'GET_FORM_165A_SUCCESS',
  GET_FORM_165A_FAILED = 'GET_FORM_165A_FAILED',
  DELETE_FORM_165A_REQUESTED = 'DELETE_FORM_165A_REQUESTED',
  DELETE_FORM_165A_SUCCESS = 'DELETE_FORM_165A_SUCCESS',
  DELETE_FORM_165A_FAILED = 'DELETE_FORM_165A_FAILED',
  CREATE_UPDATE_LEAVE_PERIOD_165A_INIT = 'CREATE_UPDATE_LEAVE_PERIOD_165A_INIT',
  CREATE_UPDATE_LEAVE_PERIOD_165A_REQUESTED = 'CREATE_UPDATE_LEAVE_PERIOD_165A_REQUESTED',
  CREATE_UPDATE_LEAVE_PERIOD_165A_SUCCESS = 'CREATE_UPDATE_LEAVE_PERIOD_165A_SUCCESS',
  CREATE_UPDATE_LEAVE_PERIOD_165A_FAILED = 'CREATE_UPDATE_LEAVE_PERIOD_165A_FAILED',
  EDIT_LEAVE_PERIOD_165A = 'EDIT_LEAVE_PERIOD_165A',
  SET_IS_ADD_LEAVE_PERIOD_165A = 'SET_IS_ADD_LEAVE_PERIOD_165A',
  SET_ADD_PERIOD_STEP_165A = 'SET_ADD_PERIOD_STEP_165A',
  DELETE_LEAVE_PERIOD_165A_REQUESTED = 'DELETE_LEAVE_PERIOD_165A_REQUESTED',
  DELETE_LEAVE_PERIOD_165A_SUCCESS = 'DELETE_LEAVE_PERIOD_165A_SUCCESS',
  UNLOCK_FORM_165A = 'UNLOCK_FORM_165A',
  SAVE_FORM_165A_REQUESTED = 'SAVE_FORM_165A_REQUESTED',
  SAVE_AND_REVIEW_FORM_165A_REQUESTED = 'SAVE_AND_REVIEW_FORM_165A_REQUESTED',
  SAVE_FORM_165A_SUCCESS = 'SAVE_FORM_165A_SUCCESS',
  SAVE_FORM_165A_FAILED = 'SAVE_FORM_165A_FAILED',
  CLEAR_FORM_165A_STATUS = 'CLEAR_FORM_165A_STATUS',
  SET_FORM_165A_STEP = 'SET_FORM_165A_STEP',
  SET_STORE_DATA_FORM_165A = 'SET_STORE_DATA_FORM_165A',
  SUBMIT_FORM_165A_REQUESTED = 'SUBMIT_FORM_165A_REQUESTED',
  SUBMIT_FORM_165A_SUCCESS = 'SUBMIT_FORM_165A_SUCCESS',
  OVERRIDE_FORM_165A_EXCEPTIONS = 'OVERRIDE_FORM_165A_EXCEPTIONS',
  SET_FORM_165A_SUBMIT_STATUS = 'SET_FORM_165A_SUBMIT_STATUS',
  SET_FORM_165A_SAVING_STATUS = 'SET_FORM_165A_SAVING_STATUS',
  GET_FORM_165A_PDF_REQUESTED = 'GET_FORM_165A_PDF_REQUESTED',
  GET_FORM_165A_FRENCH_PDF_REQUESTED = 'GET_FORM_165A_FRENCH_PDF_REQUESTED',
  EDIT_IN_LEAVE_PERIOD_165A = 'EDIT_IN_LEAVE_PERIOD_165A'
}

export const setForm165aRequestNumberAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165aActionTypes.SET_REQUEST_NUMBER_FORM_165A,
  data
});

export const createForm165aAction: ActionCreator<EapAction> = (data: {
  memberSearchResult: MemberSearchResult;
}) => ({
  type: form165aActionTypes.CREATE_FORM_165A,
  data
});

export const createForm165aInNewTabAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form165aActionTypes.CREATE_FORM_165A_IN_NEW_TAB,
  data
});

export const getForm165aAction: ActionCreator<EapAction> = (data: string) => ({
  type: form165aActionTypes.GET_FORM_165A_REQUESTED,
  data
});

export const getForm165aSuccessAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  leavePeriods: Array<LeavePeriod>;
  memberInfo: MemberSearchResult;
  commentsToMember: string;
  notes: string;
  exceptions: Array<ExceptionsObject>;
}) => ({
  type: form165aActionTypes.GET_FORM_165A_SUCCESS,
  data
});

export const getForm165aFailedAction: ActionCreator<EapAction> = () => ({
  type: form165aActionTypes.GET_FORM_165A_FAILED
});

export const deleteForm165aAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165aActionTypes.DELETE_FORM_165A_REQUESTED,
  data
});

export const deleteForm165aSuccessAction: ActionCreator<EapAction> = () => ({
  type: form165aActionTypes.DELETE_FORM_165A_SUCCESS
});

export const deleteForm165aFailedAction: ActionCreator<EapAction> = () => ({
  type: form165aActionTypes.DELETE_FORM_165A_FAILED
});

export const createUpdateLeavePeriod165aInitAction: ActionCreator<EapAction> = () => ({
  type: form165aActionTypes.CREATE_UPDATE_LEAVE_PERIOD_165A_INIT
});

export const createUpdateLeavePeriod165aAction: ActionCreator<EapAction> = (data: {
  formData: Form165aData;
  isNonPurchasable?: boolean;
  preventWizardUpdate?: boolean;
  resetLeavePeriod?: boolean;
}) => ({
  type: form165aActionTypes.CREATE_UPDATE_LEAVE_PERIOD_165A_REQUESTED,
  data
});

export const createUpdateLeavePeriod165aSuccessAction: ActionCreator<EapAction> = (data: {
  formData: Form165aData;
  leavePeriods: Array<LeavePeriod>;
  activeIntervalNumber: string;
}) => ({
  type: form165aActionTypes.CREATE_UPDATE_LEAVE_PERIOD_165A_SUCCESS,
  data
});

export const createUpdateLeavePeriod165aFailedAction: ActionCreator<EapAction> = () => ({
  type: form165aActionTypes.CREATE_UPDATE_LEAVE_PERIOD_165A_FAILED
});

export const editLeavePeriod165aAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165aActionTypes.EDIT_LEAVE_PERIOD_165A,
  data
});

export const editInLeavePeriod165aAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165aActionTypes.EDIT_IN_LEAVE_PERIOD_165A,
  data
});

export const setIsAddLeavePeriod165aAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: form165aActionTypes.SET_IS_ADD_LEAVE_PERIOD_165A,
  data
});

export const setAddPeriodStep165aAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165aActionTypes.SET_ADD_PERIOD_STEP_165A,
  data
});

export const deleteLeavePeriod165aAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165aActionTypes.DELETE_LEAVE_PERIOD_165A_REQUESTED,
  data
});

export const deleteLeavePeriod165aSuccessAction: ActionCreator<EapAction> = (
  data: Array<LeavePeriod>
) => ({
  type: form165aActionTypes.DELETE_LEAVE_PERIOD_165A_SUCCESS,
  data
});

export const unlockForm165aAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165aActionTypes.UNLOCK_FORM_165A,
  data
});

export const saveForm165aAction: ActionCreator<EapAction> = (data: {
  commentsToMember: string;
  notes: string;
  exceptions?: Array<ExceptionsObject>;
  submitForm?: boolean;
}) => ({
  type: form165aActionTypes.SAVE_FORM_165A_REQUESTED,
  data
});

export const saveAndReviewForm165aAction: ActionCreator<EapAction> = (data: {
  commentsToMember: string;
  notes: string;
}) => ({
  type: form165aActionTypes.SAVE_AND_REVIEW_FORM_165A_REQUESTED,
  data
});

export const saveForm165aSuccessAction: ActionCreator<EapAction> = (data: {
  commentsToMember: string;
  notes: string;
}) => ({
  type: form165aActionTypes.SAVE_FORM_165A_SUCCESS,
  data
});

export const saveForm165aFailedAction: ActionCreator<EapAction> = () => ({
  type: form165aActionTypes.SAVE_FORM_165A_FAILED
});

export const clearForm165aStatusAction: ActionCreator<EapAction> = () => ({
  type: form165aActionTypes.CLEAR_FORM_165A_STATUS
});

export const setForm165aStepAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165aActionTypes.SET_FORM_165A_STEP,
  data
});

export const setStoreDataForm165aAction: ActionCreator<EapAction> = (
  data: Form165aData
) => ({
  type: form165aActionTypes.SET_STORE_DATA_FORM_165A,
  data
});

export const submitForm165aAction: ActionCreator<EapAction> = () => ({
  type: form165aActionTypes.SUBMIT_FORM_165A_REQUESTED
});

export const submitForm165aSuccessAction: ActionCreator<EapAction> = (data: {
  exceptions: Array<ExceptionsObject>;
}) => ({
  type: form165aActionTypes.SUBMIT_FORM_165A_SUCCESS,
  data
});

export const overrideForm165aExceptionsAction: ActionCreator<EapAction> = (
  data: Array<ExceptionsObject>
) => ({
  type: form165aActionTypes.OVERRIDE_FORM_165A_EXCEPTIONS,
  data
});

export const setForm165aSubmitStatusAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165aActionTypes.SET_FORM_165A_SUBMIT_STATUS,
  data
});

export const setForm165aSavingStatusAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165aActionTypes.SET_FORM_165A_SAVING_STATUS,
  data
});

export const getForm165aPDFAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  formNo: string;
  employerNo: string;
  isView: boolean;
}) => ({
  type: form165aActionTypes.GET_FORM_165A_PDF_REQUESTED,
  data
});

export const getForm165aFrenchPDFAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  isView: boolean;
}) => ({
  type: form165aActionTypes.GET_FORM_165A_FRENCH_PDF_REQUESTED,
  data
});
