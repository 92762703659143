import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { ExactTheme } from 'styles/theme';
import { IconNamesSmall } from 'components/v2/atomic/icon/Icons';
import Icon, { IconName } from 'components/v2/atomic/icon/Icon';
import Button from 'components/v2/atomic/button/Button';
import { useThemeUI } from 'theme-ui';
import { useRouter } from 'next/router';
import {
  convertHyphenatedIDToTitle,
  isValidDropdown,
  NavPageItems
} from './utils/utils';

const DynamicNavLinkDropdown = ({
  items,
  buttonText
}: {
  items: Record<string, NavPageItems>;
  buttonText: string;
}) => {
  const [visibleSubItems, setVisibleSubItems] = useState(false);
  return (
    <li>
      <div
        key={`${buttonText}_navLinkID`}
        onMouseEnter={() => setVisibleSubItems(true)}
        sx={{
          display: 'flex',
          fontSize: '1.125rem',
          alignItems: 'center',
          justifyContent: 'space-between',
          lineHeight: '1.6875rem',
          padding: '10px 18px',
          borderRadius: '5px',
          color: 'brandNavy100',
          textDecoration: 'none',
          backgroundColor: 'white',
          ':hover': {
            backgroundColor: '#f2f8fc',
            textDecoration: 'none'
          }
        }}
      >
        <div>{buttonText}</div>
        <Icon color="grey" icon={IconNamesSmall.ARROW_RIGHT} />
        {visibleSubItems ? (
          <DynamicSubItems
            setIsDropdownOpen={() => {}}
            isNestedSubItems
            items={items}
          />
        ) : null}
      </div>
    </li>
  );
};

const DynamicNavButton = ({
  id,
  buttonText,
  isDropdown,
  subMenuItems,
  url
}: {
  id: string;
  buttonText: string;
  isDropdown: boolean;
  subMenuItems?: Record<string, NavPageItems>;
  url?: string;
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [iconName, setIconName] = useState<IconName>(IconNamesSmall.ARROW_DOWN);
  const ctx = useThemeUI();
  const theme = ctx.theme as ExactTheme;
  const router = useRouter();
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as HTMLElement)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick, true);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, true);
    };
  }, [wrapperRef]);

  const NavButton = () => {
    useEffect(() => {
      if (isDropdown && !isDropdownOpen) {
        setIconName(IconNamesSmall.ARROW_DOWN);
      } else if (isDropdown && isDropdownOpen) {
        setIconName(IconNamesSmall.ARROW_UP);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDropdownOpen]);

    const buttonStyles = {
      maxHeight: '100%',
      fontFamily: 'body',
      color: 'white',
      backgroundColor: 'brandNavy100',
      border: 'none',
      svg: {
        color: 'white'
      },
      '&:disabled': {
        color: 'grey80',
        backgroundColor: 'white',
        svg: {
          color: 'grey80'
        }
      },
      '&:hover:not(:disabled)': {
        border: 'none',
        textDecoration: 'none',
        backgroundColor: 'brandNavy75',
        color: 'white',
        svg: {
          color: 'white'
        }
      },
      '&:active:not(:disabled)': {
        textDecoration: 'none',
        border: 'none',
        color: `${theme.colors.white} !important`,
        svg: {
          color: `${theme.colors.white} !important`
        }
      },
      '&:focus': {
        outline: 'none',
        color: 'white'
      },
      '&:focus-visible:not(disabled)': {
        backgroundColor: 'extendedBlue25',
        border: 'none',
        color: 'brandNavy100',
        outline: `2px solid ${theme.colors.extendedBlue100}`,
        svg: {
          color: 'extendedBlue100'
        }
      }
    };

    if (!isDropdown && url) {
      const navLink = new URL(url, window.origin);
      return (
        <Button
          sxOverride={{ ...buttonStyles }}
          id={id}
          variant="simple"
          disabled={false}
          isIconButton={!buttonText}
          onClick={() => {
            router.push(navLink);
          }}
        >
          {buttonText}
        </Button>
      );
    }

    if (isDropdown && subMenuItems && !isValidDropdown(subMenuItems))
      return null;

    if (isDropdown) {
      return (
        <div>
          <Button
            sxOverride={{ ...buttonStyles }}
            id={id}
            variant="simple"
            disabled={false}
            isIconButton={!buttonText}
            title={buttonText}
            onClick={() => {
              setIsDropdownOpen(!isDropdownOpen);
            }}
          >
            {buttonText}
            {!!iconName && <Icon color="white" icon={iconName} />}
          </Button>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {isDropdown &&
      isValidDropdown(subMenuItems as Record<string, NavPageItems>) ? (
        <div ref={wrapperRef}>
          <DynamicSubItems
            setIsDropdownOpen={setIsDropdownOpen}
            isNestedSubItems={false}
            isDropdownOpen={isDropdownOpen}
            items={subMenuItems as Record<string, NavPageItems>}
          >
            <NavButton />
          </DynamicSubItems>
        </div>
      ) : (
        <NavButton />
      )}
    </>
  );
};

const DynamicSubItems = ({
  items,
  isNestedSubItems,
  isDropdownOpen,
  setIsDropdownOpen,
  children
}: {
  items: Record<string, NavPageItems>;
  isNestedSubItems: boolean;
  isDropdownOpen?: boolean;
  setIsDropdownOpen: (isOpen: boolean) => void;
  children?: React.ReactNode;
}) => (
  <div>
    {children}
    {isDropdownOpen && (
      <div
        sx={{
          position: 'absolute',
          minWidth: '180px',
          right: isNestedSubItems ? '-100%' : undefined,
          top: isNestedSubItems ? '50%' : undefined,
          zIndex: '20'
        }}
      >
        <ul
          sx={{
            padding: '8px',
            borderRadius: '2px',
            boxShadow:
              '0 0 0 1px rgba(17,20,24,.1),0 2px 4px rgba(17,20,24,.2),0 8px 24px rgba(17,20,24,.2)',
            backgroundColor: 'white',
            display: 'relative'
          }}
        >
          {Object.keys(items).map(key => {
            if (!items[key].title && !items[key].slug && key === 'icon')
              return null;
            if (!items[key].title && !items[key].slug) {
              return (
                <DynamicNavLinkDropdown
                  items={
                    (items[key] as unknown) as Record<string, NavPageItems>
                  }
                  buttonText={key}
                />
              );
            }
            const navLink = new URL(items[key].slug, window.origin);
            if (items[key].title && items[key].slug && !items[key].hideItem) {
              return (
                <li key={`dynamic-nav-dropdown-link-item-${key}`}>
                  <div>
                    <Link
                      sx={{ textDecoration: 'none' }}
                      href={navLink}
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      <div
                        sx={{
                          fontSize: '1.125rem',
                          alignItems: 'flex-start',
                          lineHeight: '1.6875rem',
                          padding: '10px 18px',
                          borderRadius: '5px',
                          color: 'brandNavy100',
                          textDecoration: 'none',
                          backgroundColor: 'white',
                          ':hover': {
                            backgroundColor: '#f2f8fc',
                            textDecoration: 'none'
                          }
                        }}
                      >
                        {items[key].title}
                      </div>
                    </Link>
                  </div>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
    )}
  </div>
);

const DynamicNav = ({
  navStructure,
  sortedNavKeys
}: {
  navStructure: Record<string, NavPageItems>;
  sortedNavKeys: string[];
}) => (
  <div
    sx={{
      lineHeight: '1.6rem',
      padding: '1rem 2rem',
      backgroundColor: 'brandNavy100'
    }}
  >
    <ul
      sx={{
        display: 'flex',
        backgroundColor: 'brandNavy100',
        margin: '0 auto',
        gap: '0.25rem',
        alignItems: 'center',
        maxWidth: '1500px'
      }}
    >
      {sortedNavKeys.map(key => {
        if (
          navStructure[key].title &&
          navStructure[key].slug &&
          navStructure[key].hideItem
        )
          return null;
        if (!navStructure[key].title && !navStructure[key].slug) {
          return (
            <li key={`dynamic-nav-item-${key}_dropdown`}>
              <DynamicNavButton
                id={key}
                buttonText={convertHyphenatedIDToTitle(key)}
                isDropdown
                subMenuItems={
                  (navStructure[key] as unknown) as Record<string, NavPageItems>
                }
              />
            </li>
          );
        }
        return (
          <li key={`dynamic-nav-item-${key}_button`}>
            <DynamicNavButton
              id={key}
              buttonText={navStructure[key].title}
              isDropdown={false}
              key={key}
              url={navStructure[key].slug}
            />
          </li>
        );
      })}
    </ul>
  </div>
);

export default DynamicNav;
