import { MemberSearchResult, BankingInfoModel } from 'interfaces';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData, postData, putData, deleteData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const checkMemberContext = (data: MemberSearchResult) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143`, data);

export const createForm143 = (data: MemberSearchResult) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143`, data);

export const getForm143 = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/${requestNumber}`,
    undefined,
    requestNumber
  );

export const saveForm143 = (data: any, requestNumber: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const calculatePA = (
  data: any,
  requestNumber: string,
  servicePeriodNumber: string
) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/${requestNumber}/calc-pa/${servicePeriodNumber}`,
    data
  );

export const getBankingInfo = (
  institutionNumber: string,
  transitNumber: string
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/common/banking-info/${institutionNumber}/${transitNumber}`
  );

export const getPDF143 = (
  requestNumber: string,
  formNo: string,
  oracleReportInd: string
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/common/pdf/${requestNumber}?formNo=${formNo}&report=${oracleReportInd}`,
    undefined,
    requestNumber,
    'blob'
  );

export const getAdvanceElection = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/${requestNumber}/advance-election`
  );

export const postAdvanceElection = (
  requestNumber: string,
  data: BankingInfoModel
) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/${requestNumber}/advance-election`,
    data
  );

export const deleteAdvanceElection = (requestNumber: string) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/${requestNumber}/advance-election`
  );

export const submitForm143 = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v2/requests/e143/submit/${requestNumber}`,
    undefined,
    undefined,
    requestNumber
  );

export const getRetro = (requestNumber: string, servicePeriodNo: number) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/${requestNumber}/retropay/${servicePeriodNo}`,
    undefined,
    requestNumber
  );

export const setRetro = (
  requestNumber: string,
  servicePeriodNo: number,
  data: any
) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/${requestNumber}/retropay/${servicePeriodNo}`,
    data,
    undefined,
    requestNumber
  );

export const deleteRetro = (requestNumber: string, servicePeriodNo: number) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/${requestNumber}/retropay/${servicePeriodNo}`,
    undefined,
    requestNumber
  );

export const getDisabilityBenefitStartDate = (data: {
  membershipNo: number;
  employmentModel: {
    dateLastWorked: string;
    totalDisabilityDate: string;
    lastDateRegEarnings: null;
    recurrenceInd: 'N';
  };
}) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/calc-disben-date`,
    data
  );
