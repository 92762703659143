import Drawer from 'components/v2/atomic/drawer/Drawer';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/v2/atomic/card/Card';
import Icon, { IconNames, IconSize } from 'components/v2/atomic/icon/Icon';
import {
  IconNamesLarge,
  IconNamesSmall,
  IconNamesStatus,
  IconNamesXSmall
} from 'components/v2/atomic/icon/Icons';
import Button from 'components/v2/atomic/button/Button';
import SelectInput, {
  SelectMenuItem
} from 'components/v2/atomic/selectInput/SelectInput';
import TextArea from 'components/v2/atomic/textArea/TextArea';
import Modal from 'components/v2/atomic/modal/Modal';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import Divider from 'components/v2/atomic/divider/Divider';
import CalloutNotification from 'components/v2/atomic/calloutNotification/CalloutNotification';
import Spinner from 'components/v2/atomic/spinner/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'types';
import logger from 'utils/logger/client-side';
import {
  createAppointment,
  updateAppointment,
  getStaffAvailability,
  getStaffMembers
} from 'services/microsoftBookings';
import { MsBookingsAvailability } from 'pages/api/microsoft-bookings/staff-availability.api';
import {
  addDays,
  format,
  formatISO,
  isAfter,
  isFuture,
  parse,
  parseISO,
  set,
  startOfDay
} from 'date-fns';
import { format as formatTz } from 'date-fns-tz';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import { useAsync, useInterval } from 'react-use';
import { RobotoFlex } from 'styles/theme';
import { BaseStyles } from 'theme-ui';
import ScrollTo from 'components/scrollTo/ScrollTo';

import TimeSlotGrid from './TimeSlotGrid';
import Appointments from './Appointments';

import {
  addNewAppointmentAction,
  setIsOpen,
  updateAppointmentAction
} from './microsoftBookingsDrawerReducer';
import { Appointment } from './interfaces';
import { PostBookingSurvey } from './PostBookingSurvey';

const twoHoursFromNow = () => {
  const now = new Date();
  return set(now, {
    hours: now.getHours() + 2,
    minutes: now.getMinutes() - 1,
    seconds: 59,
    milliseconds: 0
  });
};

export type BookingScreen =
  | 'intro'
  | 'meetingDetails'
  | 'timeDetails'
  | 'reviewDetails'
  | 'confirmation';

const NavButtonIcon = (props: {
  className?: string;
  size?: number;
  disabled?: boolean;
  orientation?: 'left' | 'right' | 'up' | 'down';
}) => (
  <Icon
    icon={
      props.orientation === 'left'
        ? IconNamesXSmall.ARROW_LEFT
        : IconNamesXSmall.ARROW_RIGHT
    }
    size={20}
    sx={{
      border: '1px solid #003A70',
      borderRadius: '50%',
      padding: '0.5rem'
    }}
    {...props}
  />
);

export const MicrosoftBookingsDrawer = () => {
  const { t } = useTranslation('microsoft-bookings');
  const dispatch = useDispatch();

  const { email, firstName, lastName } = useSelector(
    (state: RootState) => state.userSession.userData
  );
  const { selectGroupNumber } = useSelector(
    (state: RootState) => state.selectEmployers
  );
  const { isOpen, appointments, loadingAppointmentsError } = useSelector(
    (state: RootState) => state.microsoftBookingsDrawer
  );
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );

  const isEmployersBookingsUpcomingAptsEnabled = isEnabled(
    featureFlags,
    currentFlags.EMPLOYER_BOOKINGS_ENHANCEMENTS
  );

  const isSprigSurveyEnabled = isEnabled(
    featureFlags,
    currentFlags.EMPLOYER_BOOKINGS_SPRIG_SURVEY
  );

  const [currentScreen, setCurrentScreen] = useState<BookingScreen>('intro');

  const [requestText, setRequestText] = useState('');
  const [textAreaError, setTextAreaError] = useState(false);
  const [cancelAppointmentError, setCancelAppointmentError] = useState(false);
  const [loadedStaffMember, setLoadedStaffMember] = useState(false);
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [loadingAvailibilityError, setLoadingAvailibilityError] = useState(
    false
  );
  const [loadingAppointmentCreation, setLoadingAppointmentCreation] = useState(
    false
  );
  const [loadingAppointmentUpdate, setLoadingAppointmentUpdate] = useState(
    false
  );
  const [appointmentCreationError, setAppointmentCreationError] = useState(
    false
  );
  const [appointmentUpdateError, setAppointmentUpdateError] = useState(false);
  const [loadingAppointments, setLoadingAppointments] = useState(false);
  const timeIntervals = useMemo(
    () => [
      { key: '30', value: t('30_MINUTES') },
      { key: '60', value: t('60_MINUTES') }
    ],
    [t]
  );

  const [staffMembers, setStaffMembers] = useState<Array<SelectMenuItem>>([]);
  const [staffMember, setStaffMember] = useState(staffMembers[0]);
  const [selectedStaffIds, setSelectedStaffIds] = useState<Array<string>>([]);
  const [timeInterval, setTimeInterval] = useState<SelectMenuItem>(
    timeIntervals[0]
  );
  const [exitBookingModalOpen, setExitBookingModalOpen] = useState(false);
  const [cancelEditModalOpen, setCancelEditModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date>();
  const [selectedTime, setSelectedTime] = useState<string>();
  const [availabilityForSelectedDay, setAvailabilityForSelectedDay] = useState<
    Array<MsBookingsAvailability>
  >([]);
  const [updatingAppointment, setUpdatingAppointment] = useState(false);
  const [
    selectedAppointment,
    setSelectedAppointment
  ] = useState<Appointment | null>(null);

  const [timeSlotError, setTimeSlotError] = useState(false);

  // Check available time slots are 2 hours from now every 30 seconds
  // when the current screen is timeDetails
  useInterval(
    () => {
      const newAvailability = availabilityForSelectedDay.map(slot =>
        isAfter(parseISO(slot.dateTimeISO), twoHoursFromNow())
          ? slot
          : { ...slot, available: false }
      );
      setAvailabilityForSelectedDay(newAvailability);
    },
    currentScreen === 'timeDetails' ? 30000 : null
  );

  const meetingDetailsChanged = useMemo(() => {
    if (selectedAppointment && selectedDay && selectedTime) {
      const selectedTimeInterval =
        selectedAppointment.duration === '30'
          ? timeIntervals[0]
          : timeIntervals[1];
      return (
        format(selectedDay, 'EEEE, MMMM do, yyyy') !==
          selectedAppointment.startDay ||
        selectedTime !==
          format(
            parse(selectedAppointment.startTime ?? '', 'h:mm aa', new Date()),
            'HH:mm'
          ) ||
        requestText !== selectedAppointment.meetingNotes ||
        staffMember?.key !== selectedAppointment.staffID[0] ||
        timeInterval !== selectedTimeInterval
      );
    }
    return false;
  }, [
    selectedAppointment,
    selectedDay,
    selectedTime,
    timeIntervals,
    requestText,
    staffMember,
    timeInterval
  ]);

  const appointmentStartDate =
    new Date().getHours() >= 16 ? addDays(new Date(), 1) : new Date();

  const initializeUpdateAppointment = (appointment: Appointment) => {
    setUpdatingAppointment(true);
    setSelectedAppointment(appointment);
    setSelectedDay(
      parse(appointment.startDay, 'EEEE, MMMM do, yyyy', new Date())
    );

    setSelectedTime(
      appointment.startTime
        ? format(parse(appointment.startTime, 'h:mm aa', new Date()), 'HH:mm')
        : ''
    );
    setSelectedStaffIds(appointment.staffID);
    setStaffMember(
      staffMembers.find(staff => staff.key === appointment?.staffID[0]) ??
        staffMembers[0]
    );
    setTimeInterval(
      appointment.duration === '30' ? timeIntervals[0] : timeIntervals[1]
    );
    setRequestText(appointment.meetingNotes ?? '');
    setCurrentScreen('reviewDetails');
  };

  useAsync(async () => {
    if (selectGroupNumber) {
      const response = await getStaffMembers(selectGroupNumber);

      if (response.success) {
        const staffMembersList = response.data;
        setStaffMembers(staffMembersList);
        setStaffMember(staffMembersList[0]);
        setLoadedStaffMember(true);
      }
    }
  }, [selectGroupNumber]);

  const openExitBookingModal = useCallback(() => {
    setExitBookingModalOpen(true);
  }, []);

  const closeExitBookingModal = useCallback(() => {
    setExitBookingModalOpen(false);
  }, []);

  const exitBooking = useCallback(() => {
    setCurrentScreen('intro');
    setExitBookingModalOpen(false);
    setCancelEditModalOpen(false);
    setUpdatingAppointment(false);
    setTextAreaError(false);
    setSelectedDay(undefined);
    setSelectedTime(undefined);
    setStaffMember(staffMembers[0]);
    setTimeInterval(timeIntervals[0]);
    setRequestText('');
    setLoadingAvailibilityError(false);
    setLoadingAppointmentCreation(false);
    setLoadingAppointmentUpdate(false);
    setAppointmentCreationError(false);
    setAppointmentUpdateError(false);
    setCancelAppointmentError(false);
    setLoadingAppointments(false);
    setSelectedStaffIds([]);
    setAvailabilityForSelectedDay([]);
  }, [staffMembers, timeIntervals]);

  const ExitBookingButton = () => (
    <Button
      onClick={openExitBookingModal}
      id="exitBookingButton"
      variant="outline"
      color="orange"
      sx={{ width: '16.75rem' }}
    >
      {t('EXIT_BOOKING')}
    </Button>
  );

  const CancelEditButton = () => (
    <Button
      onClick={() => {
        setCancelEditModalOpen(true);
      }}
      id="cancelEditButton"
      variant="outline"
      color="orange"
      sx={{ width: '16.75rem' }}
    >
      {t('CANCEL_EDIT')}
    </Button>
  );

  const ConfirmBookingButton = () => (
    <Button
      onClick={async () => {
        setLoadingAppointmentCreation(true);
        setAppointmentCreationError(false);
        try {
          const response = await createAppointment(selectGroupNumber, {
            staff: selectedStaffIds,
            day: selectedDay
              ? formatISO(selectedDay, {
                  representation: 'date'
                })
              : undefined,
            time: selectedTime,
            request: requestText,
            duration: timeInterval.key,
            email,
            name: `${firstName} ${lastName}`
          });

          if (response.success) {
            const newAppointment = response.data;
            dispatch(addNewAppointmentAction(newAppointment));
            setCurrentScreen('confirmation');
            setTimeout(exitBooking, 3000);
            setAppointmentCreationError(false);
          } else {
            logger.error(
              'Microsoft Graph API issue: Failed to create an appointment'
            );
            setAppointmentCreationError(true);
          }
        } catch (err) {
          logger.error(
            'There was an error while creating an appointment',
            err || ''
          );
          setAppointmentCreationError(true);
        } finally {
          setLoadingAppointmentCreation(false);
        }
      }}
      id="reviewDetailsCardConfirmBookingButton"
      sx={{ width: '16.75rem' }}
    >
      {t('CONFIRM_BOOKING')}
    </Button>
  );

  const UpdateBookingButton = () => (
    <Button
      disabled={!meetingDetailsChanged}
      onClick={async () => {
        setLoadingAppointmentUpdate(true);
        setAppointmentUpdateError(false);
        try {
          if (!selectedAppointment) {
            return;
          }
          const response = await updateAppointment(selectGroupNumber, {
            appointmentId: selectedAppointment.id,
            staff: selectedStaffIds,
            day: selectedDay
              ? formatISO(selectedDay, {
                  representation: 'date'
                })
              : undefined,
            time: selectedTime,
            request: requestText,
            duration: timeInterval.key
          });

          if (response.success) {
            const updatedAppointment = {
              id: selectedAppointment.id,
              startDay: selectedDay
                ? format(selectedDay, 'EEEE, MMMM do, yyyy')
                : '',
              startTime: format(
                new Date(`2000-01-01T${selectedTime}:00`),
                'h:mm a'
              ),
              duration: timeInterval.key,
              time: selectedDay
                ? formatTz(
                    parseISO(
                      `${formatISO(selectedDay, {
                        representation: 'date'
                      })}T${selectedTime}`
                    ),
                    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
                    {
                      timeZone: 'America/Toronto'
                    }
                  )
                : '',
              tag: 'Updated',
              meetingNotes: requestText,
              staffID: selectedStaffIds
            };
            dispatch(updateAppointmentAction(updatedAppointment));
            setCurrentScreen('confirmation');
            setTimeout(exitBooking, 3000);
            setAppointmentUpdateError(false);
          } else {
            logger.error(
              'Microsoft Graph API issue: Failed to update an appointment'
            );
            setAppointmentUpdateError(true);
          }
        } catch (err) {
          logger.error(
            'There was an error while updating an appointment',
            err || ''
          );
          setAppointmentUpdateError(true);
        } finally {
          setLoadingAppointmentUpdate(false);
        }
      }}
      id="updateBookingButton"
      sx={{ width: '16.75rem' }}
    >
      {t('UPDATE_BOOKING')}
    </Button>
  );

  const updateStaffAvailability = async (day: Date) => {
    try {
      const response = await getStaffAvailability(selectGroupNumber, {
        staffMember: staffMember?.key === 'Anyone' ? staffMembers : staffMember,
        day: formatISO(startOfDay(day), {
          representation: 'date'
        }),
        timeInterval: Number(timeInterval.key)
      });

      if (response.success) {
        const slots = response.data;
        setAvailabilityForSelectedDay(slots);
        setLoadingAvailibilityError(false);
      } else {
        logger.error(
          'Microsoft Graph API issue: Failed to fetch staff availability'
        );
        setLoadingAvailibilityError(true);
      }
    } catch (err) {
      logger.error(
        'There was an error while fetching staff availability',
        err || ''
      );
      setLoadingAvailibilityError(true);
    } finally {
      setLoadingAvailability(false);
    }

    setCurrentScreen('timeDetails');
    setTimeSlotError(false);
  };

  return (
    <>
      {isSprigSurveyEnabled && (
        <PostBookingSurvey
          appointments={appointments}
          staffMembersList={staffMembers}
        />
      )}
      <Drawer
        header={
          <h2
            sx={{
              p: ['0 2rem', '0 1.5rem', null],
              fontFamily: RobotoFlex.style.fontFamily,
              fontSize: 'large',
              fontWeight: 'heading',
              color: 'brandNavy100'
            }}
          >
            {t('DRAWER_HEADER')}
          </h2>
        }
        isOpen={isOpen}
        onClose={() => {
          if (currentScreen !== 'intro') {
            updatingAppointment
              ? setCancelEditModalOpen(true)
              : setExitBookingModalOpen(true);
          } else {
            exitBooking();
            dispatch(setIsOpen(false));
          }
        }}
        isActionButtonsEnabled={false}
        enableScrollToTopOnChange={false}
        canOutsideClickClose
        minWidth="680px"
      >
        {currentScreen === 'intro' && (
          <>
            {loadingAppointmentsError && (
              <CalloutNotification id="loadStaffMemberError" intent="danger">
                {t('ERROR_LOAD_APPOINTMENTS')}
              </CalloutNotification>
            )}
            {cancelAppointmentError && (
              <CalloutNotification
                id="cancelAppointmentError"
                intent="danger"
                sx={{
                  marginTop: loadingAppointmentsError ? '1rem' : undefined
                }}
              >
                {t('ERROR_CANCEL_APPOINTMENT')}
              </CalloutNotification>
            )}
            <br />
            <Card accentColor="extendedBlue100" accent id="startingCard">
              <Icon icon={IconNamesLarge.OPENING_TIMES} size={IconSize.LARGE} />
              <br />
              {appointments.filter(appointment =>
                isFuture(parseISO(appointment.time))
              ).length === 0 ? (
                <BaseStyles>
                  <h3>{t('STARTING_CARD_TITLE')}</h3>
                  <div sx={{ fontSize: '18px', mt: '16px' }}>
                    {isEmployersBookingsUpcomingAptsEnabled && (
                      <>
                        <p>{t('STARTING_CARD_BODY_2')}</p>
                        <p sx={{ marginBottom: 0 }}>
                          {t('STARTING_CARD_BODY_1')}
                        </p>
                      </>
                    )}
                    {!isEmployersBookingsUpcomingAptsEnabled && (
                      <>
                        <p>{t('STARTING_CARD_BODY_1')}</p>
                        <p>{t('STARTING_CARD_BODY_2')}</p>
                        <p>
                          <span>{t('STARTING_CARD_BODY_3')}</span>
                          <a
                            href="mailto:education@omers.com"
                            sx={{ mx: '3px' }}
                          >
                            education@omers.com
                          </a>
                          <span>{t('STARTING_CARD_BODY_4')}</span>
                        </p>
                      </>
                    )}
                  </div>
                </BaseStyles>
              ) : (
                <BaseStyles sx={{ padding: 0 }}>
                  <h3>{t('DRAWER_UPCOMING_MEETINGS_HEADER')}</h3>
                  {isEmployersBookingsUpcomingAptsEnabled && (
                    <>
                      <div
                        sx={{
                          display: 'flex',
                          gap: '0.35rem',
                          p: {
                            marginBottom: '0.25rem'
                          },
                          svg: {
                            marginTop: '0.25rem'
                          }
                        }}
                      >
                        <p
                          sx={{
                            whiteSpace: 'nowrap',
                            width: 'fit-content'
                          }}
                        >
                          {t('DRAWER_UPCOMING_MEETINGS_MODIFY_TEXT')}
                        </p>
                        <Icon icon={IconNames.PEN} color="darkGreyText" />
                        <p>{t('DRAWER_UPCOMING_MEETINGS_TEXT_BUTTON')}</p>
                      </div>
                      <div
                        sx={{
                          display: 'flex',
                          gap: '0.35rem',
                          p: {
                            marginBottom: '0.25rem'
                          },
                          svg: {
                            marginTop: '0.25rem'
                          }
                        }}
                      >
                        <p sx={{ whiteSpace: 'nowrap', width: 'fit-content' }}>
                          {t('DRAWER_UPCOMING_MEETINGS_CANCEL_TEXT')}
                        </p>
                        <Icon icon={IconNames.DELETE} color="darkGreyText" />
                        <p>{t('DRAWER_UPCOMING_MEETINGS_TEXT_BUTTON')}</p>
                      </div>
                    </>
                  )}

                  <Divider id="upcoming-appointments-top-divider" />
                  <div
                    sx={{
                      maxHeight: 'calc(100vh - 37.5rem)',
                      overflowY: 'auto'
                    }}
                  >
                    <Appointments
                      appointments={appointments}
                      staffMembers={staffMembers}
                      setCancelAppointmentError={setCancelAppointmentError}
                      initializeUpdateAppointment={initializeUpdateAppointment}
                    />
                  </div>
                </BaseStyles>
              )}
              <br />
              <Button
                minWidth="245px"
                onClick={() => {
                  setCurrentScreen('meetingDetails');
                }}
                id="startBookingButton"
                disabled={loadingAppointmentsError || loadingAppointments}
              >
                {t('STARTING_CARD_BUTTON')}
              </Button>

              {loadingAppointments && (
                <div>
                  <br />
                  <Spinner
                    id="loadingAppointments"
                    isFullScreen={false}
                    size={50}
                  />
                </div>
              )}
            </Card>
          </>
        )}

        {currentScreen === 'meetingDetails' && (
          <BaseStyles>
            {!loadedStaffMember && (
              <CalloutNotification id="loadStaffMemberError" intent="danger">
                {t('ERROR_LOAD_STAFF_MEMBERS')}
              </CalloutNotification>
            )}
            <br />
            <Card
              id="meetingDetailsCard"
              sxOverride={{ height: 'hug', width: 'fill' }}
              accent
              accentColor="extendedBlue100"
            >
              <div
                sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}
              >
                <h3
                  sx={{
                    textAlign: 'center',
                    backgroundColor: 'extendedBlue25',
                    padding: '8px 16px 8px 16px'
                  }}
                >
                  {t('MEETING_DETAILS')}
                </h3>
                <div
                  sx={{
                    display: 'flex',
                    gap: '24px',
                    justifyContent: 'space-between'
                  }}
                >
                  <div sx={{ width: '80%' }}>
                    <h4>{t('MEETING_DETAILS_CARD_SELECT_STAFF')}</h4>
                    <SelectInput
                      id="selectStaffDropdown"
                      items={staffMembers}
                      onItemSelect={item => {
                        setStaffMember(item);
                        setSelectedDay(undefined);
                        setSelectedTime(undefined);
                      }}
                      activeItem={staffMember}
                      selectOptionMinWidth="15.625rem"
                      sxButtonOverride={{
                        textAlign: 'left'
                      }}
                    />
                  </div>
                  <div sx={{ width: '200px' }}>
                    <h4>{t('MEETING_DETAILS_CARD_SELECT_DURATION')}</h4>
                    <SelectInput
                      id="selectTimeIntervalDropDown"
                      items={timeIntervals}
                      onItemSelect={item => {
                        setTimeInterval(item);
                        setSelectedDay(undefined);
                        setSelectedTime(undefined);
                      }}
                      activeItem={timeInterval}
                      selectOptionMinWidth="6.875rem"
                      sxButtonOverride={{
                        textAlign: 'left'
                      }}
                    />
                  </div>
                </div>

                <div>
                  <h4>{t('MEETING_DETAILS_CARD_REQUEST')}</h4>
                  <p>{t('MEETING_DETAILS_CARD_NOTE')}</p>
                  <TextArea
                    name="requestTextArea"
                    placeholder="Type your requests"
                    maxLength={1000}
                    id="requestTextArea"
                    onChange={e => {
                      setRequestText(e.target.value);
                      setTextAreaError(false);
                    }}
                    error={textAreaError ? t('MEETING_DETAILS_CARD_ERROR') : ''}
                    rows={7}
                  >
                    {requestText}
                  </TextArea>
                </div>
              </div>

              <Divider id="meetingDetailsCardDivider" />
              <div
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: 'fill',
                  height: 'hug',
                  gap: '1.5rem',
                  paddingTop: '15px'
                }}
              >
                {updatingAppointment ? (
                  <CancelEditButton />
                ) : (
                  <ExitBookingButton />
                )}
                <Button
                  onClick={async () => {
                    if (requestText.length > 0) {
                      if (updatingAppointment && selectedDay) {
                        setLoadingAvailability(true);
                        updateStaffAvailability(selectedDay);
                      }
                      setCurrentScreen('timeDetails');
                      setTextAreaError(false);
                      setTimeSlotError(false);
                    } else {
                      setTextAreaError(true);
                    }
                  }}
                  id="meetingDetailsCardNextButton"
                  sx={{ width: '16.75rem' }}
                  disabled={!loadedStaffMember}
                >
                  {t('NEXT')}
                </Button>
              </div>
            </Card>
          </BaseStyles>
        )}

        {currentScreen === 'timeDetails' && (
          <BaseStyles>
            {loadingAvailibilityError && (
              <CalloutNotification
                id="loadStaffAvailabilityError"
                intent="danger"
              >
                {t('ERROR_LOAD_AVAILABILITY')}
              </CalloutNotification>
            )}
            <br />
            <Card
              id="timeDetailsCard"
              sxOverride={{ height: 'hug', width: 'fill' }}
              accent
              accentColor="extendedBlue100"
            >
              <div
                sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}
              >
                <h3
                  sx={{
                    textAlign: 'center',
                    backgroundColor: 'extendedBlue25',
                    padding: '8px 16px 8px 16px'
                  }}
                >
                  {t('PICK_A_DAY')}
                </h3>
                <div
                  sx={{
                    width: 'fill',
                    height: 'hug',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    borderColor: 'grey60',
                    border: '1px solid #CCCCCC'
                  }}
                >
                  <DayPicker
                    mode="single"
                    selected={selectedDay}
                    defaultMonth={
                      updatingAppointment ? selectedDay : new Date()
                    }
                    onSelect={async (day: Date | undefined) => {
                      setLoadingAvailibilityError(false);
                      setSelectedTime(undefined);
                      setAvailabilityForSelectedDay([]);
                      if (!day) {
                        setSelectedDay(undefined);
                      } else {
                        setSelectedDay(day);
                        setLoadingAvailability(true);
                        updateStaffAvailability(day);
                      }
                    }}
                    disabled={[
                      {
                        dayOfWeek: [0, 6]
                      },
                      {
                        before: appointmentStartDate,
                        after: addDays(appointmentStartDate, 14)
                      }
                    ]}
                    startMonth={appointmentStartDate}
                    sx={{
                      paddingBottom: '10px',
                      '.rdp-selected .rdp-day_button': {
                        backgroundColor: '#0078b5',
                        color: '#fff'
                      },
                      '.rdp-button_next, .rdp-button_previous': {
                        borderRadius: '50%'
                      }
                    }}
                    modifiersStyles={{
                      today: {
                        fontWeight: 'bold',
                        color: 'black'
                      }
                    }}
                    styles={{
                      root: { padding: '2rem 1rem' },
                      weekday: {
                        width: '80px',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        paddingBottom: '16px',
                        textTransform: 'uppercase'
                      },
                      day: {
                        height: '50px',
                        fontSize: '18px',
                        fontWeight: 'body'
                      },
                      month_grid: {
                        maxWidth: 'none'
                      },
                      day_button: {
                        margin: 'auto'
                      },
                      month_caption: {
                        margin: '0 0 1.5rem 1.5rem',
                        fontSize: '28px'
                      },
                      nav: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '10px'
                      }
                    }}
                    components={{
                      Chevron: props => <NavButtonIcon {...props} />
                    }}
                  />
                </div>
              </div>

              {timeSlotError && (
                <CalloutNotification
                  id="timeSlotExpired"
                  intent="danger"
                  sx={{ mt: '1.5rem' }}
                >
                  <ScrollTo scrollOnChange={[timeSlotError]} />
                  {t('TIME_SLOT_EXPIRED')}
                </CalloutNotification>
              )}

              {selectedDay && (
                <div
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    paddingTop: '24px'
                  }}
                >
                  {availabilityForSelectedDay.length > 0 && (
                    <h3
                      sx={{
                        textAlign: 'center',
                        backgroundColor: 'extendedBlue25',
                        padding: '8px 16px 8px 16px'
                      }}
                    >
                      {t('PICK_A_TIME')}
                    </h3>
                  )}

                  {loadingAvailability && (
                    <Spinner
                      id="loadingAvailabilitySpinner"
                      isFullScreen={false}
                      size={50}
                    />
                  )}

                  <TimeSlotGrid
                    originalTimeSelected={
                      updatingAppointment &&
                      staffMember?.key === selectedAppointment?.staffID[0] &&
                      timeInterval.key === selectedAppointment?.duration &&
                      format(selectedDay, 'EEEE, MMMM do, yyyy') ===
                        selectedAppointment?.startDay &&
                      selectedAppointment?.startTime
                        ? format(
                            parse(
                              selectedAppointment.startTime,
                              'h:mm aa',
                              new Date()
                            ),
                            'HH:mm'
                          )
                        : ''
                    }
                    selectedTime={selectedTime}
                    handleSelectedTime={(
                      time: string,
                      staffIds: Array<string>
                    ) => {
                      setSelectedTime(time);
                      setSelectedStaffIds(staffIds);
                    }}
                    availabilityForSelectedDay={availabilityForSelectedDay}
                  />
                </div>
              )}
              <Divider id="timeDetailsCardDivider" />
              <div
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: 'fill',
                  height: 'hug',
                  gap: '24px',
                  paddingTop: '15px'
                }}
              >
                {updatingAppointment ? (
                  <CancelEditButton />
                ) : (
                  <ExitBookingButton />
                )}
                <Button
                  onClick={() => {
                    if (selectedDay) {
                      const appointment = parseISO(
                        `${formatISO(selectedDay, {
                          representation: 'date'
                        })}T${selectedTime}`
                      );
                      if (isAfter(twoHoursFromNow(), appointment)) {
                        setTimeSlotError(true);
                        return;
                      }
                    }

                    setCurrentScreen('reviewDetails');
                  }}
                  id="timeDetailsCardNextButton"
                  sx={{ width: '16.75rem' }}
                  disabled={!selectedTime}
                >
                  {t('NEXT')}
                </Button>
              </div>
            </Card>
          </BaseStyles>
        )}

        {currentScreen === 'reviewDetails' && (
          <BaseStyles
            sx={{
              svg: { marginBottom: '0.5rem' }
            }}
          >
            {appointmentCreationError && (
              <CalloutNotification
                id="appointmentCreationError"
                intent="danger"
              >
                {t('ERROR_CREATE_APPOINTMENT')}
              </CalloutNotification>
            )}
            {appointmentUpdateError && (
              <CalloutNotification id="appointmentUpdateError" intent="danger">
                {t('ERROR_UPDATE_APPOINTMENT')}
              </CalloutNotification>
            )}
            <br />
            <Card
              id="reviewDetailsCard"
              sxOverride={{ height: 'hug', width: 'fill' }}
              accent
              accentColor="extendedBlue100"
            >
              <div
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '18px',
                  fontSize: '18px'
                }}
              >
                <h3
                  sx={{
                    textAlign: 'center',
                    backgroundColor: 'extendedBlue25',
                    padding: '8px 16px 8px 16px'
                  }}
                >
                  {t('REVIEW_DETAILS_CARD_TITLE')}
                </h3>
                <div
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '1.875rem'
                  }}
                >
                  <p sx={{ fontWeight: 'semibold' }}>{t('MEETING_DETAILS')}</p>
                  <Button
                    id="changeMeetingDetailsButton"
                    onClick={() => {
                      setCurrentScreen('meetingDetails');
                    }}
                    variant="simple"
                    sx={{
                      textDecoration: 'underline',
                      padding: '0',
                      width: '21.875rem'
                    }}
                  >
                    {t('CHANGE_MEETING_DETAILS')}
                  </Button>
                </div>

                <div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '1.875rem'
                  }}
                >
                  <Icon
                    icon={IconNamesSmall.USERS_MEETING}
                    size={IconSize.SMALL}
                  />
                  <p sx={{ marginLeft: '8px', marginTop: '15px' }}>
                    {staffMember?.value}
                  </p>
                </div>

                <div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '1.875rem'
                  }}
                >
                  <Icon icon={IconNamesSmall.HOURGLASS} size={IconSize.SMALL} />
                  <p sx={{ mx: '8px', marginTop: '15px' }}>
                    {timeInterval.value}
                  </p>
                </div>

                <div sx={{ display: 'flex' }}>
                  <Icon icon={IconNamesSmall.TODO} size={IconSize.SMALL} />
                  <p sx={{ marginLeft: '8px', wordBreak: 'break-word' }}>
                    {requestText}
                  </p>
                </div>

                <div
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '1.875rem'
                  }}
                >
                  <p sx={{ fontWeight: 'semibold' }}>
                    {t('MEETING_DATE_AND_TIME')}
                  </p>
                  <Button
                    id="changeTimeDetailsButton"
                    onClick={async () => {
                      // if updating appointment, need to get availability for selected staff member
                      if (updatingAppointment && selectedDay) {
                        setLoadingAvailability(true);
                        updateStaffAvailability(selectedDay);
                      }
                      setCurrentScreen('timeDetails');
                      setTimeSlotError(false);
                    }}
                    variant="simple"
                    sx={{
                      textDecoration: 'underline',
                      padding: '0',
                      width: '21.875rem'
                    }}
                  >
                    {t('CHANGE_TIME_DETAILS')}
                  </Button>
                </div>

                <div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '1.875rem'
                  }}
                >
                  <Icon icon={IconNamesSmall.CALENDAR} size={IconSize.SMALL} />
                  <p sx={{ marginLeft: '8px', marginTop: '12px' }}>
                    {selectedDay?.toLocaleDateString()}
                  </p>
                </div>

                <div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '1.875rem'
                  }}
                >
                  <Icon icon={IconNamesSmall.CLOCK} size={IconSize.SMALL} />
                  <p sx={{ marginLeft: '8px', marginTop: '12px' }}>
                    {format(
                      new Date(`2000-01-01T${selectedTime}:00`),
                      'h:mm a'
                    )}
                  </p>
                </div>
              </div>
              <Divider id="reviewDetailsCardDivider" />
              {updatingAppointment && (
                <div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: 'fill',
                    height: 'hug',
                    gap: '24px',
                    paddingTop: '15px'
                  }}
                >
                  {!loadingAppointmentUpdate && (
                    <>
                      <CancelEditButton /> <UpdateBookingButton />
                    </>
                  )}

                  {loadingAppointmentUpdate && (
                    <div
                      sx={{
                        padding: '0 50px',
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                        svg: {
                          marginBottom: '0'
                        }
                      }}
                    >
                      <Spinner
                        id="updatingAppointmentSpinner"
                        isFullScreen={false}
                        size={22}
                      />
                      <span
                        sx={{
                          fontWeight: 'semibold',
                          fontSize: '1.1875rem',
                          lineHeight: 'base',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {t('UPDATING_APPOINTMENT')}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {!updatingAppointment && (
                <div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: 'fill',
                    height: 'hug',
                    gap: '24px',
                    paddingTop: '15px'
                  }}
                >
                  {!loadingAppointmentCreation && (
                    <>
                      <ExitBookingButton /> <ConfirmBookingButton />
                    </>
                  )}

                  {loadingAppointmentCreation && (
                    <div
                      sx={{
                        padding: '0 50px',
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                        svg: {
                          marginBottom: '0'
                        }
                      }}
                    >
                      <Spinner
                        id="creatingAppointmentButton"
                        isFullScreen={false}
                        size={22}
                      />
                      <span
                        sx={{
                          fontWeight: 'semibold',
                          fontSize: '1.1875rem',
                          lineHeight: 'base'
                        }}
                      >
                        {t('CREATING_APPOINTMENT')}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </Card>
          </BaseStyles>
        )}

        {currentScreen === 'confirmation' && (
          <Card
            id="confirmedCard"
            sxOverride={{ height: 'hug', width: 'fill' }}
            accent
            accentColor="extendedBlue100"
          >
            <BaseStyles
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '18px',
                fontSize: '18px'
              }}
            >
              <h3
                sx={{
                  textAlign: 'center',
                  backgroundColor: 'extendedBlue25',
                  padding: '8px 16px 8px 16px'
                }}
              >
                {updatingAppointment
                  ? t('YOUR_BOOKING_HAS_BEEN_UPDATED')
                  : t('CONFIRMED_BOOKING')}
              </h3>
              <div
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingTop: '15px'
                }}
              >
                <Icon icon={IconNamesLarge.N_CHECK} size={IconSize.LARGE} />
                <p
                  sx={{
                    textAlign: 'center',
                    paddingTop: '15px'
                  }}
                >
                  <span sx={{ fontWeight: 'semibold' }}>
                    {t('THANK_YOU_FOR_BOOKING')}
                  </span>
                </p>
                <p sx={{ textAlign: 'center' }}>{t('CONFIRMATION_MESSAGE')}</p>
              </div>
            </BaseStyles>
          </Card>
        )}

        {exitBookingModalOpen && (
          <BaseStyles>
            <Modal
              id="exitBookingModal"
              isOpen={exitBookingModalOpen}
              onClose={closeExitBookingModal}
              titleText={t('EXIT_BOOKING')}
              footer={
                <div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '30px'
                  }}
                >
                  <Button
                    id="exitBookingModalContinueButton"
                    onClick={closeExitBookingModal}
                    variant="outline"
                    minWidth="210px"
                  >
                    {t('EXIT_BOOKING_MODAL_CONTINUE')}
                  </Button>
                  <Button
                    id="exitBookingModalExitButton"
                    onClick={exitBooking}
                    minWidth="210px"
                  >
                    {t('EXIT_BOOKING_MODAL_EXIT')}
                  </Button>
                </div>
              }
            >
              <p sx={{ marginBottom: 0 }}>{t('EXIT_BOOKING_MODAL_BODY')}</p>
            </Modal>
          </BaseStyles>
        )}

        {cancelEditModalOpen && (
          <BaseStyles>
            <Modal
              id="cancelEditModal"
              isOpen={cancelEditModalOpen}
              onClose={() => {
                setCancelEditModalOpen(false);
              }}
              titleText={t('CANCEL_EDIT_MODAL_TITLE')}
              footer={
                <div
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '30px'
                  }}
                >
                  <Button
                    id="cancelEditModalContinueButton"
                    onClick={() => {
                      setCancelEditModalOpen(false);
                    }}
                    variant="outline"
                    minWidth="210px"
                  >
                    {t('CANCEL_EDIT_MODAL_CONTINUE')}
                  </Button>
                  <Button
                    id="cancelEditModalExitButton"
                    onClick={exitBooking}
                    minWidth="210px"
                  >
                    {t('CANCEL_EDIT_MODAL_EXIT')}
                  </Button>
                </div>
              }
            >
              <p sx={{ marginBottom: 0 }}>{t('CANCEL_EDIT_MODAL_BODY')}</p>
            </Modal>
          </BaseStyles>
        )}

        <div
          sx={{
            display: 'flex',
            gap: '8px',
            paddingTop: '48px',
            fontSize: '18px'
          }}
        >
          <Icon icon={IconNamesStatus.INFO} size={IconSize.REGULAR} />
          <BaseStyles>
            <p>{t('DRAWER_FOOTER')}</p>
          </BaseStyles>
        </div>
      </Drawer>
    </>
  );
};
