import * as React from 'react';
import { ReactSVG } from 'react-svg';

import ToolTip from 'components/toolTip/ToolTip';
import Button from 'components/button/Button';
import Divider from 'components/divider/Divider';
import styles from './DropdownActionsButton.module.scss';

export interface ToolTipOptions {
  label: string;
  iconSrc: string;
  onClick?: Function;
  hideOption?: boolean;
  isDisabled?: boolean;
  dataTestId?: string;
}

const getToolTipActionItems = (toolTipOptions: Array<ToolTipOptions>) =>
  toolTipOptions
    .filter(toolTipOption => !toolTipOption.hideOption)
    .map((toolTipOption, index) => (
      <div key={toolTipOption.label}>
        {index !== 0 && <Divider />}
        <div
          key={toolTipOption.label}
          className={styles.toolTipOption}
          style={{
            marginBottom: '0'
          }}
        >
          <div className={styles.btn}>
            <Button
              onClick={toolTipOption.onClick}
              baseStyle="basic"
              isDisabled={toolTipOption.isDisabled}
              testingTag={toolTipOption.dataTestId}
              autoTestingTag={toolTipOption.dataTestId}
            >
              {toolTipOption.iconSrc && (
                <span className={styles.icon}>
                  <ReactSVG src={toolTipOption.iconSrc} />
                </span>
              )}
              <span className={styles.label}>{toolTipOption.label}</span>
            </Button>
          </div>
        </div>
      </div>
    ));

interface DropdownActionsButtonProps {
  btnSrc?: string;
  toolTipOptions?: Array<ToolTipOptions>;
  inline?: boolean;
  content?: any;
  customHeight?: boolean;
  isThickToolTip?: boolean;
  custBtn?: React.ReactNode;
}
const DropdownActionsButton = ({
  btnSrc = '/images/ellipsis.svg',
  toolTipOptions,
  inline = false,
  content,
  customHeight = false,
  isThickToolTip = false,
  custBtn
}: DropdownActionsButtonProps) => (
  <div className={inline ? styles.inlineButton : styles.dropdownActionsButton}>
    <ToolTip
      icon=""
      placement="bottom"
      baseStyle="border-none"
      content={
        customHeight ? (
          <div className={styles.innerContainer}>
            {toolTipOptions ? (
              <div className={styles.toolTipList}>
                {getToolTipActionItems(toolTipOptions)}
              </div>
            ) : (
              content || null
            )}
          </div>
        ) : toolTipOptions ? (
          <div className={styles.toolTipList}>
            {getToolTipActionItems(toolTipOptions)}
          </div>
        ) : (
          content || null
        )
      }
      isThinTooltip={!isThickToolTip}
    >
      <Button
        style={styles.ellipsisBtn}
        baseStyle="basic"
        data-testid="dropdown-actions-btn"
      >
        {!custBtn ? <ReactSVG src={btnSrc} /> : custBtn}
      </Button>
    </ToolTip>
  </div>
);

export default DropdownActionsButton;
