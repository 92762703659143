/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import {
  EmploymentPeriod,
  FinancialModel,
  Form143Model,
  UnmappedEmploymentPeriod,
  ContactModel,
  UnMappedChildModel
} from 'interfaces';
import {
  formatToCad,
  formatCurrencyStringToNumber,
  getPhoneAndAreaCodeFromString
} from 'utils';
import { uniq, pickBy } from 'lodash';
import { format, parseISO, parse } from 'date-fns';
import produce from 'immer';

import 'react-i18next';
import isNil from 'lodash/isNil';
import { Form143State } from '../form143Reducer';

export const formatEmploymentPeriod = (
  employmentPeriod: EmploymentPeriod | undefined
) => {
  if (employmentPeriod) {
    return {
      hashMap: {
        earnings:
          employmentPeriod.earnings !== ''
            ? formatCurrencyStringToNumber(employmentPeriod?.earnings!!)
            : null,
        credited_service:
          employmentPeriod.creditedService !== ''
            ? formatCurrencyStringToNumber(employmentPeriod?.creditedService!!)
            : null,
        pension_adjustment_amt:
          employmentPeriod.pensionAdjustment !== ''
            ? formatCurrencyStringToNumber(
                employmentPeriod?.pensionAdjustment!!
              )
            : null,
        rpp_contribution_amt:
          employmentPeriod.rppContribution !== ''
            ? formatCurrencyStringToNumber(employmentPeriod?.rppContribution!!)
            : null,
        rca_contribution_amt:
          employmentPeriod.rcaContribution !== ''
            ? formatCurrencyStringToNumber(employmentPeriod?.rcaContribution!!)
            : null
      }
    };
  }
  return {};
};

export const formatChildModel = (childModels: any[]): UnMappedChildModel[] =>
  childModels.map(child => ({
    birthDate:
      child?.contactBirthDateYear &&
      child?.contactBirthDateMonth &&
      child?.contactBirthDateDay
        ? format(
            new Date(
              Number(child?.contactBirthDateYear),
              Number(child?.contactBirthDateMonth) - 1,
              Number(child?.contactBirthDateDay)
            ),
            'yyyy-MM-dd'
          )
        : '',
    addrLine1: child?.address1 ?? '',
    addrLine2: child?.address2 ?? '',
    addrLine3: child?.apartmentUnit ?? '',
    city: child?.city ?? '',
    province: child?.province ?? '',
    postalCode: child?.postal?.trim() ?? '',
    country: child?.livingWithSpouse !== 'Y' ? child?.country ?? 'CAN' : '',
    phone:
      getPhoneAndAreaCodeFromString(child?.contactPrimaryPhone, true).number ??
      '',
    phoneAreaCode:
      getPhoneAndAreaCodeFromString(child?.contactPrimaryPhone, true)
        .areaCode ?? '',

    firstName: child?.contactMemberFirstName ?? '',
    lastName: child?.contactMemberLastName ?? '',
    contactFirstName: child?.contactMemberFirstNameNoSpouse ?? '',
    contactLastName: child?.contactMemberLastNameNoSpouse ?? '',
    liveWithSpouseInd: child?.livingWithSpouse ?? ''
  }));

export const computeRelationshipOther = (
  contactModel: ContactModel,
  alt: boolean | undefined
): string | null => {
  if (alt) {
    if (contactModel?.relationshipToMemberAlternative === 'I') {
      return 'Unknown';
    }
    return contactModel?.relationshipToMemberAlternative === 'O'
      ? contactModel?.pleaseSpecifyAlternative ?? ''
      : null;
  }
  switch (contactModel?.commonLaw) {
    case 'Y':
      return 'Common law';
    case 'D':
      return 'Spouse or common law';
    default:
      return null;
  }
};

export const computeRelationshipType = (
  contactModel: ContactModel,
  alt: string | undefined
) => {
  if (
    contactModel.pleaseSpecifyAlternative === '' &&
    contactModel.relationshipToMemberAlternative === 'O'
  ) {
    return undefined;
  }
  if (alt) {
    if (contactModel.relationshipToMemberAlternative === 'I') {
      return 'O';
    }
    return alt;
  }
  if (contactModel?.commonLaw) {
    return contactModel.commonLaw === 'N' ? 'S' : 'O';
  }
  return null;
};

export const computeContactModel = (formValues: { [key: string]: any }) => {
  if (
    !formValues.eligibleSpouse ||
    (formValues.eligibleSpouse === 'N' && formValues.eligibleChildren === 'Y')
  ) {
    return null;
  }

  return formValues.eligibleChildren !== 'Y' &&
    formValues.eligibleSpouse !== 'Y' &&
    formValues.childModel[0]
    ? formatContactModel(formValues.childModel, true)
    : formatContactModel(formValues.spouseModel);
};

export const formatContactModel = (
  contactModel: ContactModel[],
  alt?: boolean
) =>
  contactModel && contactModel[0]
    ? {
        firstName: alt
          ? contactModel[0]?.contactMemberFirstNameAlternative ?? ''
          : contactModel[0]?.contactMemberFirstName ?? '',
        lastName: alt
          ? contactModel[0]?.contactMemberLastNameAlternative ?? ''
          : contactModel[0]?.contactMemberLastName ?? '',
        birthDate:
          contactModel[0]?.contactBirthDateYear &&
          contactModel[0]?.contactBirthDateMonth &&
          contactModel[0]?.contactBirthDateDay
            ? format(
                new Date(
                  Number(contactModel[0]?.contactBirthDateYear),
                  Number(contactModel[0]?.contactBirthDateMonth) - 1,
                  Number(contactModel[0]?.contactBirthDateDay)
                ),
                'yyyy-MM-dd'
              )
            : '',
        addrLine1: alt
          ? contactModel[0]?.address1Alternative ?? ''
          : contactModel[0]?.address1 ?? '',
        addrLine2: alt
          ? contactModel[0]?.address2Alternative ?? ''
          : contactModel[0]?.address2 ?? '',
        addrLine3: alt
          ? contactModel[0]?.apartmentUnitAlternative ?? ''
          : contactModel[0]?.apartmentUnit ?? '',
        city: alt
          ? contactModel[0]?.cityAlternative ?? ''
          : contactModel[0]?.city ?? '',
        province: alt
          ? contactModel[0]?.provinceAlternative ?? ''
          : contactModel[0]?.province ?? '',
        postalCode: alt
          ? contactModel[0]?.postalAlternative?.trim() ?? ''
          : contactModel[0]?.postal?.trim() ?? '',
        country: alt
          ? contactModel[0]?.countryAlternative ?? 'CAN'
          : contactModel[0]?.country,
        phone: alt
          ? getPhoneAndAreaCodeFromString(
              contactModel[0]?.contactPrimaryPhoneAlternative,
              true
            ).number ?? ''
          : getPhoneAndAreaCodeFromString(
              contactModel[0]?.contactPrimaryPhone,
              true
            ).number ?? '',
        phoneAreaCode: alt
          ? getPhoneAndAreaCodeFromString(
              contactModel[0]?.contactPrimaryPhoneAlternative,
              true
            ).areaCode ?? ''
          : getPhoneAndAreaCodeFromString(
              contactModel[0]?.contactPrimaryPhone,
              true
            ).areaCode ?? '',
        relationshipType: computeRelationshipType(
          contactModel[0],
          alt ? contactModel[0]?.relationshipToMemberAlternative : undefined
        ),
        spouseSin: contactModel[0].contactSIN?.replace(/\s/g, '') ?? '',
        relationshipOther: computeRelationshipOther(contactModel[0], alt)
      }
    : {
        firstName: '',
        lastName: '',
        birthDate: '',
        addrLine1: '',
        addrLine2: '',
        addrLine3: '',
        city: '',
        province: '',
        postalCode: '',
        country: 'CAN',
        phone: '',
        phoneAreaCode: '',
        relationshipType: undefined,
        spouseSin: '',
        relationshipOther: ''
      };

// useFieldArray requires input fields with properly formatted defaultValues
// and cannot rely on parent useForm defaultValues
export const getPeriodDefaultValues = (
  i18n: any,
  employmentPeriod?: UnmappedEmploymentPeriod | null
) => {
  const { hashMap } = employmentPeriod ?? {};
  return {
    creditedService: !isNil(hashMap?.credited_service)
      ? hashMap!.credited_service.toString()
      : '',
    earnings: !isNil(hashMap?.earnings)
      ? formatToCad(hashMap!.earnings, i18n.language, false)
      : '',
    pensionAdjustment: !isNil(hashMap?.pension_adjustment_amt)
      ? formatToCad(hashMap!.pension_adjustment_amt, i18n.language, false)
      : '',
    rppContribution: !isNil(hashMap?.rpp_contribution_amt)
      ? formatToCad(hashMap!.rpp_contribution_amt, i18n.language, false)
      : '',
    rcaContribution: !isNil(hashMap?.rca_contribution_amt)
      ? formatToCad(hashMap!.rca_contribution_amt, i18n.language, false)
      : '',
    retroTotal: !isNil(hashMap?.retro_total)
      ? formatToCad(hashMap!.retro_total, i18n.language, false)
      : ''
  };
};

const assembleFinancialInfo = (
  formValues: { [key: string]: any },
  form143Model: Form143Model | null,
  swappedModels: boolean
) => {
  const financialInfo: FinancialModel = {
    eventDate: form143Model?.financialModel?.eventDate,
    columnYear: form143Model?.financialModel?.columnYear || [],
    form119Models: form143Model?.financialModel?.form119Models || [],
    hasLast119Supplemental:
      form143Model?.financialModel?.hasLast119Supplemental,
    hasSupplemental: form143Model?.financialModel?.hasSupplemental,
    reboundAllowed: form143Model?.financialModel?.reboundAllowed,
    retroPay: form143Model?.financialModel?.retroPay || [],
    suppAllowed: form143Model?.financialModel?.suppAllowed,
    carryOverModel: (() => {
      if (form143Model?.employmentModel?.postEventInd === 'Y') {
        const carryOverModel = form143Model?.financialModel?.carryOverModel;
        const hashMap = {
          ...carryOverModel?.hashMap,
          ...formatEmploymentPeriod(formValues.carryOver[0]).hashMap
        } as any;

        return Object.keys(hashMap).length > 0
          ? {
              ...form143Model?.financialModel?.carryOverModel,
              hashMap
            }
          : null;
      }
      return null;
    })(),
    employmentPeriodCurrYearModels: (() => {
      if (formValues?.employmentPeriodsCurrent?.length > 0) {
        return formValues.employmentPeriodsCurrent.map(
          (period: EmploymentPeriod, index: number) => {
            const hashMap =
              form143Model?.financialModel?.employmentPeriodCurrYearModels[
                index
              ]?.hashMap ?? {};
            return {
              ...form143Model?.financialModel?.employmentPeriodCurrYearModels[
                index
              ],
              hashMap: {
                ...hashMap,
                ...formatEmploymentPeriod(period).hashMap
              }
            };
          }
        );
      }
      return null;
    })(),
    employmentPeriodPrevYearModels: (() => {
      if (formValues?.employmentPeriodsPrevious?.length > 0) {
        return formValues.employmentPeriodsPrevious.map(
          (period: EmploymentPeriod, index: number) => {
            const hashMap =
              form143Model?.financialModel?.employmentPeriodPrevYearModels[
                index
              ]?.hashMap;
            return {
              ...form143Model?.financialModel?.employmentPeriodPrevYearModels[
                index
              ],
              hashMap: {
                ...hashMap,
                ...formatEmploymentPeriod(period).hashMap
              }
            };
          }
        );
      }
      return null;
    })()
  };
  if (swappedModels) {
    return undoSwapYears(financialInfo);
  }

  return financialInfo;
};

export const assemblePayload = (
  formValues: any,
  form143State: Form143State
) => {
  const emptyMaritalStatus =
    formValues.maritalStatus === '' || formValues.maritalStatus === undefined;
  const {
    form143Data,
    form143Model,
    swappedModels,
    employmentModel,
    formStep,
    attachments,
    exceptions,
    donnaExceptions,
    overrideExceptions,
    donnaOverrideWarnings
  } = form143State;
  const { noteToOmers } = form143Data;

  const { spouseModel, showType3Supp, advanceElectionInd } = {
    ...form143Model?.supportingInfoModel?.retirementModel
  };
  const {
    maritalStatus,
    firstName,
    lastName,
    middleName,
    proofOfAge,
    salutation
  } = { ...spouseModel };
  const notes = formStep === 2 ? noteToOmers : formValues.noteToOmers;
  return {
    employmentModel: pickBy(
      {
        ...employmentModel,
        ...formValues.employmentModel,
        otcftPct:
          formValues.eventType === 'D'
            ? formValues.percentageTimeWorked
            : undefined,
        otcftNotWorkedFromDate:
          formValues.eventType === 'D' && formValues.disabilityStartDate
            ? format(
                parse(formValues.disabilityStartDate, 'MM/dd/yyyy', new Date()),
                'yyyy-MM-dd'
              )
            : undefined,
        otcftNotWorkedToDate:
          formValues.eventType === 'D' && formValues.disabilityEndDate
            ? format(
                parse(formValues.disabilityEndDate, 'MM/dd/yyyy', new Date()),
                'yyyy-MM-dd'
              )
            : undefined,
        reasonForAdjustment: formValues.reasonForAdjustment
      },
      v => v !== undefined
    ),
    notes: notes?.length > 0 ? notes : null,
    stagedSecondaryMemberEmailAddress:
      !form143State.form143Model?.savedSecondaryMemberEmailAddressInd &&
      formValues.secondaryEmailAddress
        ? formValues.secondaryEmailAddress
        : '',
    exceptions: exceptions?.length > 0 ? overrideExceptions : null,
    donnaExceptions: {
      warnings:
        donnaExceptions && donnaExceptions?.warnings?.length > 0
          ? donnaOverrideWarnings
          : null
    },
    financialModel: assembleFinancialInfo(
      formValues,
      form143Model,
      swappedModels
    ),
    supportingInfoModel:
      (emptyMaritalStatus ||
        ((formValues.maritalStatus === 'C' ||
          formValues.maritalStatus === 'M') &&
          (formValues.birthDateYear === '' ||
            formValues.birthDateMonth === '' ||
            formValues.birthDateYear === '' ||
            formValues.memberFirstName === '' ||
            formValues.memberLastName === ''))) &&
      formValues.eventType !== 'V' &&
      formValues.eventType !== 'D'
        ? null
        : {
            retirementModel:
              formValues.eventType === 'R'
                ? {
                    advanceElectionInd:
                      (attachments === null || attachments?.length === 0) &&
                      formValues.advanceElectionInd === 'Y'
                        ? null
                        : formValues.advanceElectionInd || advanceElectionInd,
                    attachments:
                      formValues.advanceElectionInd === 'Y'
                        ? attachments
                        : null,
                    showType3Supp: formValues.showType3Supp || showType3Supp,
                    spouseModel:
                      formValues.eventType !== 'V' &&
                      (formValues.maritalStatus === 'C' ||
                        formValues.maritalStatus === 'M')
                        ? {
                            birthday:
                              formValues.birthDateYear &&
                              formValues.birthDateMonth &&
                              formValues.birthDateDay
                                ? format(
                                    new Date(
                                      Number(formValues.birthDateYear),
                                      Number(formValues.birthDateMonth) - 1,
                                      Number(formValues.birthDateDay)
                                    ),
                                    'yyyy-MM-dd'
                                  )
                                : '',
                            firstName: formValues.memberFirstName || firstName,
                            lastName: formValues.memberLastName || lastName,
                            maritalStatus:
                              formValues.maritalStatus || maritalStatus,
                            middleName:
                              formValues?.memberMiddleName ??
                              middleName ??
                              null,
                            proofOfAge,
                            salutation
                          }
                        : {
                            birthday: null,
                            firstName: null,
                            lastName: null,
                            maritalStatus:
                              formValues.maritalStatus || maritalStatus,
                            middleName: null,
                            proofOfAge,
                            salutation
                          },
                    type3ProvidedInd: 'N'
                  }
                : null,
            disabilityModel:
              formValues.eventType === 'D'
                ? {
                    cppDisabilityBenefitInd: formValues.cppDisabilityBenefitInd,
                    cppDisabilityBenefitStatus:
                      formValues.cppDisabilityBenefitStatus,
                    ltdBenefitInd: formValues.ltdBenefitInd,
                    showCPP: formValues.showCPP,
                    waiverAnnualEarningsAmt: formValues.waiverAnnualEarningsAmt
                      ? formatCurrencyStringToNumber(
                          formValues.waiverAnnualEarningsAmt
                        )
                      : null,
                    wsibBenefitInd:
                      formValues.wsibBenefitInd === 'N' ||
                      (formValues.wsibBenefitStatus !== '' &&
                        (formValues.wsibBenefitStatus !== 'A' ||
                          formValues.wsibMonthlyAmt !== ''))
                        ? formValues.wsibBenefitInd
                        : undefined,
                    wsibBenefitStatus:
                      formValues.wsibBenefitInd === 'Y' &&
                      formValues.wsibBenefitStatus !== '' &&
                      (formValues.wsibBenefitStatus !== 'A' ||
                        formValues.wsibMonthlyAmt !== '')
                        ? formValues.wsibBenefitStatus
                        : undefined,
                    wsibMonthlyAmt:
                      formValues.wsibBenefitStatus === 'A' &&
                      formValues.wsibMonthlyAmt !== ''
                        ? formatCurrencyStringToNumber(
                            formValues.wsibMonthlyAmt
                          )
                        : undefined
                  }
                : null,
            deathModel:
              formValues.eventType === 'V'
                ? {
                    eligibleSpouseStatus:
                      formValues.eligibleSpouse === 'D'
                        ? 'D'
                        : formValues.eligibleSpouse,
                    eligibleChildrenStatus:
                      formValues.eligibleChildren === 'D'
                        ? 'D'
                        : formValues.eligibleChildren,
                    eligibleChildrenNotes: formValues.noteToOmers,
                    contactModel: computeContactModel(formValues),
                    childModels:
                      formValues.eligibleChildren === 'Y'
                        ? formatChildModel(formValues.childModel)
                        : null
                  }
                : null
          }
  };
};

export const getDirtyValues = (
  formValues: { [key: string]: any },
  formState: { [key: string]: any }
) =>
  // Dirty values minus financial information
  Object.keys(formValues)
    .filter(
      key =>
        Object.keys(formState?.dirtyFields).includes(key) &&
        key !== `employmentPeriodsCurrent` &&
        key !== `employmentPeriodsPrevious` &&
        key !== `carryOver`
    )
    .reduce((obj: { [key: string]: any }, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = formValues[key];
      return obj;
    }, {});

// When two reconciliation years are open it's possible to receive multiple years inside
// the `employmentPeriodPrevYearModels` array. In this case we move the most recent year's entry(s)
// to the `employmentPeriodCurrYearModels` if it's empty, swapYears() accomplishes this. When assembling the payload the process
// is reversed when we assemble data to send to the API using undoSwapYears().

export const findPreviousYears = (
  financialModel: FinancialModel
): Array<number> =>
  uniq(
    financialModel?.employmentPeriodPrevYearModels?.map(
      (period: UnmappedEmploymentPeriod) =>
        parseISO(period?.hashMap?.start_date).getFullYear()
    )
  );

export const isSwap = (financialModel: FinancialModel): boolean =>
  findPreviousYears(financialModel).length > 1 &&
  (financialModel?.employmentPeriodCurrYearModels === null ||
    financialModel?.employmentPeriodCurrYearModels?.length === 0);

export const swapYears = (financialModel: FinancialModel): FinancialModel => {
  if (isSwap(financialModel)) {
    const yearToMove = Math.max(...findPreviousYears(financialModel));
    return produce(financialModel, draft => {
      draft.employmentPeriodCurrYearModels = financialModel?.employmentPeriodPrevYearModels?.filter(
        (period: UnmappedEmploymentPeriod) =>
          parseISO(period?.hashMap?.start_date).getFullYear() === yearToMove
      );
      draft.employmentPeriodPrevYearModels = financialModel?.employmentPeriodPrevYearModels?.filter(
        (period: UnmappedEmploymentPeriod) =>
          parseISO(period?.hashMap?.start_date).getFullYear() !== yearToMove
      );
    });
  }
  return financialModel;
};

export const undoSwapYears = (financialModel: FinancialModel): FinancialModel =>
  produce(financialModel, draft => {
    draft.employmentPeriodCurrYearModels = [];
    draft.employmentPeriodPrevYearModels = financialModel.employmentPeriodCurrYearModels.concat(
      financialModel.employmentPeriodPrevYearModels
    );
  });
