import {
  all,
  takeLeading,
  takeLatest,
  put,
  select,
  call,
  delay
} from 'redux-saga/effects';
import Router from 'next/router';
import { format } from 'url';

import { EapAction, RootState } from 'types';
import { responseTypes } from 'types/response-type';
import { LeavePeriod } from 'interfaces';
import {
  getApiErrorMessage,
  viewPdf,
  downloadPdf,
  featureFlagSelector
} from 'utils';
import { safeAuthEffect, safeNavHistoryBack } from 'store/sagas/sagaUtils';
import {
  lockFormSaga,
  unlockFormSaga,
  deleteFormSaga,
  clearLockedFormNotificationSaga
} from 'containers/requestForm/common/sagas/formSagaUtils';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import {
  notifyErrorAction,
  clearNotifications,
  clearLockedFormNotificationAction,
  notifyLockedFormErrorAction
} from 'containers/notifications/notificationsActions';
import { createFormFailedAction } from 'containers/memberSearch/memberSearchActions';
import {
  createForm165a,
  getForm165a,
  saveForm165a,
  createLeavePeriodForm165a,
  updateLeavePeriodForm165a,
  deleteLeavePeriodForm165a,
  submitForm165a,
  memberSearch,
  getPDF
} from 'services';
import {
  mapForm165,
  buildCreateUpdateRequestModel,
  transformInLeaveDataToApi,
  Form165aDataToApi,
  transformInLeaveDataFromApi,
  clearEndDate
} from 'containers/requestForm/common/form165/utils/form165Utils';
import { getFrenchForm165aPDF } from 'services/form165a';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import {
  form165aActionTypes,
  getForm165aFailedAction,
  getForm165aSuccessAction,
  deleteForm165aSuccessAction,
  deleteForm165aFailedAction,
  createUpdateLeavePeriod165aSuccessAction,
  createUpdateLeavePeriod165aFailedAction,
  setIsAddLeavePeriod165aAction,
  setAddPeriodStep165aAction,
  deleteLeavePeriod165aSuccessAction,
  saveForm165aSuccessAction,
  saveForm165aFailedAction,
  clearForm165aStatusAction,
  setForm165aStepAction,
  submitForm165aSuccessAction,
  submitForm165aAction,
  setForm165aSubmitStatusAction,
  setForm165aSavingStatusAction
} from './form165aActions';

export function* createForm165aSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, createForm165a, action.data);

  if (res.data) {
    // route user to newly created 165a
    yield call(
      Router.push,
      '/form/165a/[id]',
      format({ pathname: `/form/165a/${res.data.requestNumber}` })
    );
  } else {
    yield put(createFormFailedAction(res?.errors[0]?.code));
  }

  yield put(clearLoadingAction());
}

export function* createForm165aSagaInNewTab(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, createForm165a, action.data);

  if (res.data) {
    yield window.open(`/form/165a/${res.data.requestNumber}`, '_blank');
  } else {
    yield put(createFormFailedAction(res?.errors[0]?.code));
  }

  yield put(clearLoadingAction());
}

export function* getForm165aSaga(action: EapAction): any {
  const { featureFlags } = yield select(featureFlagSelector);
  const isInLeaveEnabled = isEnabled(
    featureFlags,
    currentFlags.LEAVE_PERIOD_REPORTING
  );
  yield put(startLoadingAction());

  const requestNumber = action.data;

  yield call(lockFormSaga, { ...action, data: requestNumber });

  // fetch 165a form
  const res = yield call(safeAuthEffect, getForm165a, requestNumber);

  if (res.data) {
    // fetch member info
    const memberInfoRes = yield call(safeAuthEffect, memberSearch, {
      omersMembershipNumber: res?.data?.e165aModel?.membershipNo,
      employerNumber: res?.data?.e165aModel?.employerNo
    });

    yield put(
      getForm165aSuccessAction({
        employerNo: res?.data?.e165aModel?.employerNo,
        requestNumber,
        leavePeriods: isInLeaveEnabled
          ? res.data?.e165aModel?.leavePeriods.map((period: LeavePeriod) =>
              clearEndDate(period)
            ) ?? []
          : res.data?.e165aModel?.leavePeriods ?? [],
        memberInfo:
          memberInfoRes?.data?.memberSearchResult?.memberships[0] ?? null,
        commentsToMember: res.data?.e165aModel?.commentsToMember ?? '',
        notes: res.data?.e165aModel?.notes ?? '',
        exceptions: res.data?.e165aModel?.exceptions ?? []
      })
    );
    yield put(clearLockedFormNotificationAction());

    // route user to form if not already there
    if (Router.route !== '/form/165a/[id]') {
      yield call(
        Router.push,
        '/form/165a/[id]',
        format({ pathname: `/form/165a/${requestNumber}` })
      );
    }
  } else {
    yield put(getForm165aFailedAction());
    yield put(notifyLockedFormErrorAction(getApiErrorMessage(res.errors)));

    // navigate off the request if user is already there
    if (Router.route === '/form/165a/[id]') {
      yield call(safeNavHistoryBack, '/requests');
    }
  }

  yield put(clearLoadingAction());

  // clear potential locked form notification after delay
  yield call(clearLockedFormNotificationSaga);
}

export function* deleteForm165aSaga(action: EapAction) {
  yield call(deleteFormSaga, action, {
    deleteSuccessAction: deleteForm165aSuccessAction(),
    deleteFailedAction: deleteForm165aFailedAction()
  });
}

export function* createUpdateLeavePeriod165aSaga(action: EapAction): any {
  const {
    requestNumber,
    activeIntervalNumber,
    addLeavePeriodStep
  } = yield select((state: RootState) => state.form165a);
  const { featureFlags } = yield select(featureFlagSelector);
  const isInLeaveEnabled = isEnabled(
    featureFlags,
    currentFlags.LEAVE_PERIOD_REPORTING
  );

  // delete previous leave period to allow user to change previously saved date
  if (action.data.resetLeavePeriod) {
    yield call(deleteLeavePeriodForm165a, requestNumber, activeIntervalNumber);
  }

  const transformedData = buildCreateUpdateRequestModel(
    action.data.formData,
    addLeavePeriodStep,
    null
  );

  const payload = isInLeaveEnabled
    ? transformInLeaveDataToApi(
        transformedData as Form165aDataToApi,
        action.data.formData,
        addLeavePeriodStep
      )
    : transformedData;

  // create or update leave period based on activeIntervalNumber set or null
  const res =
    !activeIntervalNumber || action.data.resetLeavePeriod
      ? yield call(createLeavePeriodForm165a, payload, requestNumber)
      : yield call(
          updateLeavePeriodForm165a,
          payload,
          requestNumber,
          activeIntervalNumber
        );

  // fetch latest 165a form data
  const resForm = yield call(safeAuthEffect, getForm165a, requestNumber);

  if (res.data && resForm.data) {
    const intervalNumber =
      !activeIntervalNumber || action.data.resetLeavePeriod
        ? res.data.intervalNumber
        : activeIntervalNumber;

    const currentLeavePeriod = resForm.data?.e165aModel?.leavePeriods?.find(
      (period: LeavePeriod) =>
        period.brokenService.intervalNumber === intervalNumber
    );
    const form165aData = {
      formData: mapForm165(currentLeavePeriod, {
        noteToMember: resForm.data?.e165aModel?.commentsToMember ?? '',
        noteToOmers: resForm.data?.e165aModel?.notes ?? ''
      }),
      leavePeriods: resForm.data?.e165aModel?.leavePeriods ?? [],
      intervalNumber
    };

    yield put(
      createUpdateLeavePeriod165aSuccessAction(
        isInLeaveEnabled
          ? transformInLeaveDataFromApi(form165aData, currentLeavePeriod)
          : form165aData
      )
    );

    if (!action.data.preventWizardUpdate) {
      // close add leave period wizard if final step
      if (
        (action.data.isNonPurchasable && addLeavePeriodStep === 2) ||
        (!action.data.isNonPurchasable && addLeavePeriodStep === 3)
      ) {
        yield put(setIsAddLeavePeriod165aAction(false));
      } else {
        yield put(setAddPeriodStep165aAction(addLeavePeriodStep + 1));
      }
    }

    yield put(clearNotifications());
    yield delay(3000);
    yield put(setForm165aSavingStatusAction(''));
  } else {
    yield put(createUpdateLeavePeriod165aFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield delay(3000);
    yield put(setForm165aSavingStatusAction(''));
  }
}

export function* deleteLeavePeriod165aSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const { requestNumber } = yield select((state: RootState) => state.form165a);

  const res = yield call(deleteLeavePeriodForm165a, requestNumber, action.data);

  // fetch latest 165a form data
  const resForm = yield call(safeAuthEffect, getForm165a, requestNumber);

  if ((res.status === 200 || res.status === 204) && resForm.data) {
    yield put(
      deleteLeavePeriod165aSuccessAction(resForm.data?.e165aModel?.leavePeriods)
    );
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(clearLoadingAction());
}

export function* unlockForm165aSaga(action: EapAction) {
  const { submissionComplete } = yield select(
    (state: RootState) => state.form165a
  );

  // prevent unnecessary unlock if form successfully submitted
  if (!submissionComplete) {
    yield call(unlockFormSaga, action);
  }
}

export function* clearNotificationsSaga() {
  yield put(clearNotifications());
}

export function* saveForm165aSaga(action: EapAction): any {
  const { requestNumber } = yield select((state: RootState) => state.form165a);

  const res = yield call(
    safeAuthEffect,
    saveForm165a,
    {
      commentsToMember:
        action.data.commentsToMember?.length > 0
          ? action.data.commentsToMember
          : null,
      notes: action.data.notes?.length > 0 ? action.data.notes : null,
      exceptions: action.data.exceptions
    },
    requestNumber
  );

  if (res.data) {
    yield put(saveForm165aSuccessAction(action.data));

    if (
      action.type === form165aActionTypes.SAVE_AND_REVIEW_FORM_165A_REQUESTED
    ) {
      yield put(setForm165aStepAction(2));
    }

    if (action.data.submitForm) {
      yield put(submitForm165aAction());
    }
  } else {
    yield put(saveForm165aFailedAction());
  }
}

export function* clearForm165aStatusSaga() {
  yield delay(3000);
  yield put(clearForm165aStatusAction());
}

export function* submitForm165aSaga(): any {
  yield put(startLoadingAction());
  yield put(setForm165aSubmitStatusAction('submitting'));

  const { requestNumber } = yield select((state: RootState) => state.form165a);

  const res = yield call(safeAuthEffect, submitForm165a, requestNumber);

  if (res?.data?.submitResponse) {
    yield put(clearNotifications());
    yield put(submitForm165aSuccessAction(res.data.submitResponse));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm165aSubmitStatusAction(''));
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(setForm165aSubmitStatusAction('error'));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm165aSubmitStatusAction(''));
  }
}

export function* getPDF165aSaga(action: EapAction): any {
  const { isView } = action.data;
  yield put(startLoadingAction());
  const res = yield call(
    safeAuthEffect,
    action.type === form165aActionTypes.GET_FORM_165A_FRENCH_PDF_REQUESTED
      ? getFrenchForm165aPDF
      : getPDF,
    action.type === form165aActionTypes.GET_FORM_165A_FRENCH_PDF_REQUESTED
      ? action.data.requestNumber
      : action.data
  );
  yield put(clearLoadingAction());

  if (
    res?.type === responseTypes.OCTET_STREAM ||
    res?.type === responseTypes.PDF
  ) {
    const fileName =
      action.data.formNo === '165_ele'
        ? 'Member Election Form'
        : 'e-Form 165a: Leave Period (Employer Reporting)';

    if (isView) {
      viewPdf(res, fileName);
    } else {
      downloadPdf(res, fileName);
    }

    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* form165aSaga(): any {
  yield all([
    yield takeLeading(form165aActionTypes.CREATE_FORM_165A, createForm165aSaga),
    yield takeLatest(
      form165aActionTypes.GET_FORM_165A_REQUESTED,
      getForm165aSaga
    ),
    yield takeLatest(
      form165aActionTypes.DELETE_FORM_165A_REQUESTED,
      deleteForm165aSaga
    ),
    yield takeLatest(
      form165aActionTypes.CREATE_UPDATE_LEAVE_PERIOD_165A_REQUESTED,
      createUpdateLeavePeriod165aSaga
    ),
    yield takeLatest(
      form165aActionTypes.DELETE_LEAVE_PERIOD_165A_REQUESTED,
      deleteLeavePeriod165aSaga
    ),
    yield takeLatest(form165aActionTypes.UNLOCK_FORM_165A, unlockForm165aSaga),
    yield takeLatest(
      [
        form165aActionTypes.SET_IS_ADD_LEAVE_PERIOD_165A,
        form165aActionTypes.SET_ADD_PERIOD_STEP_165A
      ],
      clearNotificationsSaga
    ),
    yield takeLatest(
      [
        form165aActionTypes.SAVE_FORM_165A_REQUESTED,
        form165aActionTypes.SAVE_AND_REVIEW_FORM_165A_REQUESTED
      ],
      saveForm165aSaga
    ),
    yield takeLatest(
      [
        form165aActionTypes.SAVE_FORM_165A_SUCCESS,
        form165aActionTypes.SAVE_FORM_165A_FAILED
      ],
      clearForm165aStatusSaga
    ),
    yield takeLatest(
      form165aActionTypes.SUBMIT_FORM_165A_REQUESTED,
      submitForm165aSaga
    ),
    yield takeLatest(
      [
        form165aActionTypes.GET_FORM_165A_FRENCH_PDF_REQUESTED,
        form165aActionTypes.GET_FORM_165A_PDF_REQUESTED
      ],
      getPDF165aSaga
    ),
    yield takeLatest(
      form165aActionTypes.CREATE_FORM_165A_IN_NEW_TAB,
      createForm165aSagaInNewTab
    )
  ]);
}
