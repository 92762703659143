import { DataObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import {
  Form102DataModel,
  Form102Request,
  Form102SubmitDataModel
} from 'interfaces';
import { DonnaApiResponse } from '@omers-packages/next-donna-gateway-handler';
import { getData, postData, putData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const getForm102EnrolmentReasons = (
  employmentStatus: string,
  sameDates: boolean
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e102/enrolment-reason?employmentStatus=${employmentStatus}&sameDates=${sameDates}`
  );

/**
 * @deprecated This V1 endpoint will be removed once the V2 e-access API feature flag is permanently enabled
 * TODO: Remove this endpoint and associated feature flag after full V2 migration is complete.
 */
export const createForm102V1 = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e102`, data);

export const createForm102 = (
  data: Form102Request
): Promise<DonnaApiResponse<string>> =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v2/requests/e102`, data);

/**
 * @deprecated This V1 endpoint will be removed once the V2 e-access API feature flag is permanently enabled
 * TODO: Remove this endpoint and associated feature flag after full V2 migration is complete.
 */
export const submitForm102V1 = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e102/submit/${requestNumber}`,
    undefined,
    undefined,
    requestNumber
  );

export const submitForm102 = (
  requestNumber: string
): Promise<DonnaApiResponse<Form102SubmitDataModel>> =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v2/requests/e102/submit/${requestNumber}`,
    undefined,
    undefined,
    requestNumber
  );

/**
 * @deprecated This V1 endpoint will be removed once the V2 e-access API feature flag is permanently enabled
 * TODO: Remove this endpoint and associated feature flag after full V2 migration is complete.
 */
export const updateForm102V1 = (data: DataObj, requestNumber: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e102/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const updateForm102 = (
  data: Form102Request,
  requestNumber: string
): Promise<DonnaApiResponse<string>> =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v2/requests/e102/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

/**
 * @deprecated This V1 endpoint will be removed once the V2 e-access API feature flag is permanently enabled
 * TODO: Remove this endpoint and associated feature flag after full V2 migration is complete.
 */
export const getForm102V1 = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e102/${requestNumber}`,
    undefined,
    requestNumber
  );

export const getForm102 = (
  requestNumber: string
): Promise<DonnaApiResponse<Form102DataModel>> =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v2/requests/e102/${requestNumber}`,
    undefined,
    requestNumber
  );

export const validatePhoneNumber = (data: DataObj, requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/phone/validation`,
    data,
    undefined,
    requestNumber
  );
