import { ReactSVG } from 'react-svg';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { RootState } from 'types';
import Button from 'components/button/Button';
import NavBackButton from 'components/navBackButton/NavBackButton';
import Spinner from 'components/spinner/Spinner';
import DropdownActionsButton, {
  ToolTipOptions
} from 'components/dropdownActionsButton/DropdownActionsButton';
import ProgressBar, {
  ProgressBarStep
} from 'components/progressBar/ProgressBar';
import { openNewMessageWithPresetDataAction } from 'containers/messages/messagesActions';

import Notification from 'components/notification/Notification';
import { DonnaExceptionsObject, ExceptionsObject } from 'interfaces';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import styles from './FormHeader.module.scss';

const cx = classNames.bind(styles);

export interface FormHeaderProps {
  formTitle: string;
  secondaryTitle?: string;
  extraHeaderInfo?: string;
  altReviewText?: string;
  altSaveText?: string;
  backButtonText?: string;
  formDescription?: string | JSX.Element | null;
  onBackCallback?: Function;
  useBackCallback?: boolean;
  onSaveCallback?: Function;
  showSubmit?: boolean;
  saveStatus?: string;
  submitStatus?: string;
  onReviewCallback?: Function;
  onSubmitCallback?: Function;
  onResetFormCallback?: Function;
  onDeleteFormCallback?: Function;
  onSendCorrespondenceCallback?: Function;
  submitDisabled?: boolean;
  reviewDisabled?: boolean;
  resetDisabled?: boolean;
  deleteDisabled?: boolean;
  saveDisabled?: boolean;
  hideSave?: boolean;
  hideEverything?: boolean;
  hideDelete?: boolean;
  hideReset?: boolean;
  hideReview?: boolean;
  hideSendCorrespondence?: boolean;
  sendCorrespondenceTopic?: string;
  isSearchHeader?: boolean;
  progressBarProps: { steps: ProgressBarStep[]; selectedStep: number };
  exceptions?: Array<ExceptionsObject>;
  donnaExceptions?: DonnaExceptionsObject | null;
  dropdownActions?: boolean;
  dropdownActionsList?: ToolTipOptions[];
  useCallBackFunction?: boolean;
}

const FormHeader = ({
  formDescription,
  extraHeaderInfo,
  altReviewText,
  altSaveText,
  formTitle,
  secondaryTitle,
  backButtonText,
  hideEverything = false,
  onBackCallback,
  useBackCallback = true,
  onSaveCallback,
  saveStatus = '',
  submitStatus = '',
  onReviewCallback,
  onResetFormCallback,
  onDeleteFormCallback,
  onSendCorrespondenceCallback,
  showSubmit,
  onSubmitCallback,
  reviewDisabled,
  submitDisabled,
  resetDisabled,
  deleteDisabled,
  saveDisabled,
  hideSave,
  isSearchHeader = false,
  progressBarProps: { steps, selectedStep },
  hideDelete,
  hideReset,
  hideReview,
  hideSendCorrespondence,
  sendCorrespondenceTopic = '',
  exceptions,
  donnaExceptions,
  dropdownActionsList,
  useCallBackFunction = false
}: FormHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('requests');
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );
  const formHeaderClass = cx({
    formHeader: true,
    formHeaderWithNewNav: isEnabled(featureFlags, currentFlags.NEW_NAV_HEADER),
    noProgressBar: isSearchHeader
  });
  const { omersUser } = useSelector(
    (state: RootState) => state.userSession.userData
  );
  const form143State = useSelector((state: RootState) => state.form143);

  const getStatus = (type: string) => {
    switch (type) {
      case 'saving':
        return (
          <span className={styles.saveStatus}>
            <Spinner
              isFullScreen={false}
              size="small"
              message={t('REQUESTS_FORM_HEADER_STATUS_SAVING_MSG')}
            />
          </span>
        );
      case 'success':
        return (
          <span className={`${styles.saveStatus} ${styles.success}`}>
            <ReactSVG src="/images/green-check.svg" />
            <span>{t('REQUESTS_FORM_HEADER_STATUS_SAVED')}</span>
          </span>
        );
      case 'error':
        return (
          <span className={`${styles.saveStatus} ${styles.error}`}>
            <ReactSVG src="/images/exclamation-triangle-solid.svg" />
            <span>{t('REQUESTS_FORM_HEADER_STATUS_UNABLE_TO_SAVE')}</span>
          </span>
        );
      default:
        return (
          <div className={styles.headerWrapper}>
            <Button
              baseStyle="outlineWhite"
              isDisabled={saveDisabled || omersUser}
              onClick={onSaveCallback}
              autoTestingTag="submit-save-button"
            >
              <span>
                {altSaveText || t('REQUESTS_FORM_HEADER_STATUS_SAVE')}
              </span>
            </Button>
          </div>
        );
    }
  };

  const getSubmitStatus = (type: string) => {
    switch (type) {
      case 'submitting':
        return (
          <span className={styles.saveStatus}>
            <Spinner
              isFullScreen={false}
              size="small"
              message={t('REQUESTS_FORM_HEADER_SUBMIT_STATUS_SUBMITTING')}
            />
          </span>
        );
      case 'success':
        return (
          <span className={`${styles.saveStatus} ${styles.success}`}>
            <ReactSVG src="/images/green-check.svg" />
            <span>{t('REQUESTS_FORM_HEADER_SUBMIT_STATUS_SUBMITTED')}</span>
          </span>
        );
      case 'error':
        return (
          <span className={`${styles.saveStatus} ${styles.error}`}>
            <ReactSVG src="/images/exclamation-triangle-solid.svg" />
            <span>{t('REQUESTS_FORM_HEADER_SUBMIT_STATUS_ERROR')}</span>
          </span>
        );
      case 'warning':
        return (
          <span className={`${styles.saveStatus} ${styles.warning}`}>
            <ReactSVG src="/images/exclamation-triangle-solid.svg" />
            <span>{t('REQUESTS_FORM_HEADER_SUBMIT_STATUS_WARNING')}</span>
          </span>
        );
      default:
        return (
          <Button
            type="button"
            onClick={onSubmitCallback}
            isDisabled={submitDisabled || omersUser}
            autoTestingTag="submit-request-button"
          >
            <span>{t('REQUESTS_FORM_HEADER_SUBMIT')}</span>
            <ReactSVG src="/images/right-arrow.svg" />
          </Button>
        );
    }
  };

  if (hideEverything) {
    return (
      <div className={formHeaderClass}>
        <div
          className={cx({
            actionBar: true,
            increaseMargin: true
          })}
        >
          <div className={styles.actionBtns}>
            <div className={styles.btn}>
              <NavBackButton
                iconSrc="/images/back-arrow.svg"
                testingTag="back-button"
                btnText={backButtonText}
                useCallBackFunction={useCallBackFunction}
                callback={onBackCallback ?? undefined}
              />
            </div>
            <div className={styles.headerWrapper}>
              <div className={styles.actionBarTitle}>
                <div>
                  <span>{formTitle}</span>
                  {formDescription && <span className={styles.pipe}>|</span>}
                  {formDescription && (
                    <span className={styles.actionBarTitleDesc}>
                      {formDescription}
                    </span>
                  )}
                </div>
                {extraHeaderInfo && (
                  <div className={styles.extraHeaderInfo}>
                    {extraHeaderInfo}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isSearchHeader && (
          <ProgressBar steps={steps} currentStep={selectedStep} />
        )}
      </div>
    );
  }

  const dropdownActionOptions = dropdownActionsList || [
    {
      label: t('REQUESTS_FORM_HEADER_SEND_E_CORRESPONDENCE'),
      iconSrc: '/images/e-correspondence.svg',
      onClick: () => {
        if (onSendCorrespondenceCallback) {
          onSendCorrespondenceCallback();
        } else {
          dispatch(
            openNewMessageWithPresetDataAction({
              topic: sendCorrespondenceTopic,
              newTab: true
            })
          );
        }
      },
      isDisabled: omersUser,
      hideOption: hideSendCorrespondence,
      dataTestId: 'send-e-corr'
    },
    {
      label: t('REQUESTS_FORM_HEADER_RESET_FORM'),
      iconSrc: '/images/reset.svg',
      onClick: onResetFormCallback,
      isDisabled: resetDisabled || omersUser,
      hideOption: hideReset,
      dataTestId: 'reset-button'
    },
    {
      label: t('REQUESTS_FORM_HEADER_DELETE_FORM'),
      iconSrc: '/images/delete.svg',
      onClick: onDeleteFormCallback,
      isDisabled: deleteDisabled || omersUser,
      hideOption: hideDelete,
      dataTestId: 'delete-button'
    }
  ];

  const exceptionTypes = [
    ...Array.from(
      new Set(exceptions?.map(ex => ex.exception.toLowerCase().trim()))
    )
  ].sort();

  const filteredResults = exceptionTypes?.map(eType =>
    exceptions?.filter(rec => rec.exception.toLowerCase().trim() === eType)
  );

  const exceptionList = (type: Array<ExceptionsObject>) =>
    type?.map(ex => (
      <Notification
        key={ex.exception.toString()}
        type={ex.exception.toLowerCase().trim()}
        messages={[
          <>
            <span className="semiBold">{ex.exception}</span>
            <p>{ex.itemtx}</p>
          </>
        ]}
        noMargin
        marginBottom
      />
    ));

  const donnaExceptionList = () => {
    if (donnaExceptions?.errors && donnaExceptions?.errors.length > 0) {
      return donnaExceptions.errors.map(error => (
        <Notification
          type="error"
          key={error.errorCode.toString()}
          messages={[
            <>
              <span className="semiBold">{t('common:PAGE_ERROR_TITLE')}</span>
              <p>{error.message}</p>
            </>
          ]}
          noMargin
          marginBottom
        />
      ));
    }
    return donnaExceptions?.warnings?.map(warning => (
      <Notification
        type="warning"
        key={warning.warningId.toString()}
        messages={[
          <>
            <span className="semiBold">{t('common:WARNING')}</span>
            <p>{warning.message}</p>
          </>
        ]}
        noMargin
        marginBottom
      />
    ));
  };

  const exceptionBtn = (type: Array<ExceptionsObject>) => (
    <Notification
      type={type?.[0]?.exception?.toLowerCase()}
      messages={[
        `${type?.length} ${type?.[0]?.exception}${type?.length > 1 ? 's' : ''}`
      ]}
      customIconPath=""
      inlineMini
    />
  );

  const donnaExceptionBtn = () => {
    if (donnaExceptions?.errors && donnaExceptions?.errors.length > 0) {
      return (
        <Notification
          type="error"
          messages={[
            `${donnaExceptions?.errors?.length} ${t(
              'common:PAGE_ERROR_TITLE'
            )}${
              donnaExceptions?.errors && donnaExceptions?.errors?.length > 1
                ? 's'
                : ''
            }`
          ]}
          customIconPath=""
          inlineMini
        />
      );
    }
    return (
      <Notification
        type="warning"
        messages={[
          `${donnaExceptions?.warnings?.length} ${t('common:WARNING')}${
            donnaExceptions?.warnings && donnaExceptions?.warnings?.length > 1
              ? 's'
              : ''
          }`
        ]}
        customIconPath=""
        inlineMini
      />
    );
  };

  return (
    <div className={formHeaderClass}>
      <div className={styles.actionBar}>
        <div className={styles.actionBtns}>
          <div className={styles.btn}>
            <NavBackButton
              useCallBackFunction={useBackCallback}
              callback={onBackCallback}
              btnText={backButtonText}
              testingTag="back-button"
            />
          </div>
          <div className={styles.headerWrapper}>
            <div className={styles.actionBarTitle}>
              <div>
                <span>{formTitle}</span>
                {formDescription && <span className={styles.pipe}>|</span>}
                {formDescription && (
                  <span className={styles.actionBarTitleDesc}>
                    {formDescription}
                  </span>
                )}
                <span className={styles.exceptionsBlock}>
                  {form143State?.form143Data?.eventType === 'T' ||
                  form143State?.form143Data?.eventType === 'R'
                    ? ((donnaExceptions &&
                        donnaExceptions?.errors?.length > 0) ||
                        (donnaExceptions &&
                          donnaExceptions?.warnings?.length > 0)) && (
                        <DropdownActionsButton
                          inline
                          content={donnaExceptionList()}
                          customHeight
                          isThickToolTip
                          custBtn={donnaExceptionBtn()}
                        />
                      )
                    : filteredResults?.map(eType => (
                        <DropdownActionsButton
                          key="Error"
                          inline
                          content={exceptionList(eType ?? [])}
                          customHeight
                          isThickToolTip
                          custBtn={exceptionBtn(eType ?? [])}
                        />
                      ))}
                </span>
              </div>
              {extraHeaderInfo && (
                <div className={styles.extraHeaderInfo}>{extraHeaderInfo}</div>
              )}
            </div>
          </div>
        </div>
        {secondaryTitle && (
          <p style={{ textAlign: 'center', margin: '0px' }}>{secondaryTitle}</p>
        )}
        <div className={styles.submitBtns}>
          {dropdownActionOptions && dropdownActionOptions?.length > 1 && (
            <DropdownActionsButton toolTipOptions={dropdownActionOptions} />
          )}
          {hideSave || (
            <span className={styles.save}>{getStatus(saveStatus)}</span>
          )}
          {showSubmit && (
            <span className={styles.review}>
              {getSubmitStatus(submitStatus)}
            </span>
          )}

          {!showSubmit && !hideReview && (
            <span className={styles.review}>
              <Button
                type="button"
                onClick={onReviewCallback}
                isDisabled={reviewDisabled}
                autoTestingTag="submit-review-button"
                testingTag="submit-review-button"
              >
                {altReviewText || t('REQUESTS_FORM_HEADER_REVIEW')}
              </Button>
            </span>
          )}
        </div>
      </div>
      {steps.length > 0 && (
        <ProgressBar steps={steps} currentStep={selectedStep} />
      )}
    </div>
  );
};

export default FormHeader;
