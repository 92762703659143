import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { getOverrideReasons } from 'services';
import { getApiErrorMessage } from 'utils';
import { notificationActionTypes } from 'containers/notifications/notificationsActions';
import { UserSession } from 'interfaces';
import { overrideReasonsActionTypes } from './overrideReasonsActions';

export function* fetchOverrideReasons(): any {
  const { userData }: UserSession = yield select(state => state.userSession);

  const res = yield call(getOverrideReasons, userData.omersUser);

  if (res.result?.data) {
    yield put({
      type: overrideReasonsActionTypes.OVERRIDE_REASONS_SUCCEEDED,
      data: res.result.data
    });
    yield put({
      type: notificationActionTypes.CLEAR_NOTIFICATION
    });
  } else {
    yield put({
      type: overrideReasonsActionTypes.OVERRIDE_REASONS_FAILED
    });
    yield put({
      type: notificationActionTypes.NOTIFY_ERROR,
      data: getApiErrorMessage(res.errors)
    });
  }
}

export function* fetchOverrideReasonsSaga() {
  yield all([
    takeLatest(
      overrideReasonsActionTypes.OVERRIDE_REASONS_REQUESTED,
      fetchOverrideReasons
    )
  ]);
}
