import Button from 'components/v2/atomic/button/Button';
import { format, parseISO } from 'date-fns';
import { MsBookingsAvailability } from 'pages/api/microsoft-bookings/staff-availability.api';

interface TimeSlotGridProps {
  selectedTime: string | undefined;
  originalTimeSelected: string | undefined;
  handleSelectedTime: (time: string, staffIds: Array<string>) => void;
  availabilityForSelectedDay: Array<MsBookingsAvailability>;
}

const TimeSlotGrid = ({
  originalTimeSelected,
  selectedTime,
  handleSelectedTime,
  availabilityForSelectedDay
}: TimeSlotGridProps) => (
  <div
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: '40px',
      rowGap: '14px'
    }}
  >
    {availabilityForSelectedDay.map(slot => (
      <Button
        key={slot.time}
        id={`time-slot-button-${slot.time}`}
        disabled={!slot.available && originalTimeSelected !== slot.time}
        variant="outline"
        onClick={() => {
          handleSelectedTime(slot.time, slot.staffIds);
        }}
        sx={{
          border: '1px solid #707372',
          borderRadius: '0',
          fontWeight: '400',
          color: selectedTime === slot.time ? 'white' : 'darkGreyText',
          pointerEvents:
            !slot.available && originalTimeSelected !== slot.time
              ? 'none'
              : 'auto',
          backgroundColor: selectedTime === slot.time ? 'extendedBlue100' : '',
          '&:hover': {
            backgroundColor:
              slot.available && slot.time !== selectedTime
                ? 'grey20'
                : 'extendedBlue100',
            opacity: slot.available && slot.time !== selectedTime ? '50%' : '',
            color:
              slot.available && slot.time !== selectedTime ? 'black' : 'grey80',
            borderColor:
              slot.available && slot.time !== selectedTime
                ? 'black'
                : 'extendedBlue100'
          }
        }}
      >
        {format(parseISO(slot.dateTimeISO), 'h:mm a')}
      </Button>
    ))}
  </div>
);

export default TimeSlotGrid;
