import { isEqual } from 'lodash';
import {
  getDateFromYMDStrings,
  formatCurrencyStringToNumber,
  getPhoneAndAreaCodeFromString
} from 'utils';
import { convertDateMDYtoYMD } from 'utils/date';
import { Form102Data } from '../form102Reducer';

/**
 * Generate create/update form 102 request v1 model
 */
export const buildCreateUpdateRequestForm102ModelV1 = (
  selectEmployerNumber: number,
  requestNumber: string,
  username: string,
  data: Form102Data,
  isValidAddress: boolean,
  isNFTEnabled: boolean
) => {
  const hireDate = isNFTEnabled
    ? convertDateMDYtoYMD(data.hireDate)
    : getDateFromYMDStrings(
        data.hireDateYear,
        data.hireDateMonth,
        data.hireDateDay
      );
  let enrolmentDate: string | null = '';
  if (data.enrolSameAsHire) {
    enrolmentDate = hireDate;
  } else {
    enrolmentDate = isNFTEnabled
      ? convertDateMDYtoYMD(data.enrolDate)
      : getDateFromYMDStrings(
          data.enrolDateYear,
          data.enrolDateMonth,
          data.enrolDateDay
        );
  }
  let prevPPHireDate: string | null = '';
  if (data.hasPriorPension === 'Y') {
    if (isNFTEnabled) {
      prevPPHireDate = convertDateMDYtoYMD(data.priorPensionDate);
    } else {
      prevPPHireDate = getDateFromYMDStrings(
        data.priorPensionDateYear,
        data.priorPensionDateMonth,
        data.priorPensionDateDay
      );
    }
  }
  return {
    employerNo: selectEmployerNumber.toString(),
    employeeEmployment: {
      requestNumber: requestNumber ? parseInt(requestNumber, 10) : null,
      updateUserid: username,
      birthDate: getDateFromYMDStrings(
        data.birthDateYear,
        data.birthDateMonth,
        data.birthDateDay
      ),
      employeeId: data.employeeId || null,
      employmentStatus: data.employmentStatus,
      enrolmentDate,
      firstName: data.firstName.trim(),
      hireDate,
      electionDate: convertDateMDYtoYMD(data.electionDate),
      hireEnrolDatesSameInd:
        data.enrolSameAsHire || isEqual(data.hireDate, data.enrolDate)
          ? 'Y'
          : 'N',
      languageCode: 'E',
      lastName: data.lastName.trim(),
      middleName: data.middleName.trim() || null,
      nra: data.normalRetirementAge || null,
      previousEmployer: null,
      previousTerminationDate: null,
      sex: data.sex,
      sin: data.sin ? data.sin.replace(/ /g, '') : null,
      departmentId: data.departmentID || null,
      annualSalaryRate:
        data.salary === 'A'
          ? formatCurrencyStringToNumber(data.salaryRate)
          : null,
      hourlyRate:
        data.salary === 'H'
          ? formatCurrencyStringToNumber(data.salaryRate)
          : null,
      prevPPParticipationInd: data.hasPriorPension,
      prevPPName: data.hasPriorPension === 'Y' ? data.priorPensionName : null,
      prevPPHireDate,
      pssOccupation: data.occupation,
      pssAffiliation: data.memberAffiliation,
      enrolReason: data.enrolReason || null,
      releaseInfoConsentInd: data.signedForm132 || null,
      restructInd: data.hasDivestment,
      occupationCode: data.occupation || null,
      employeeAffiliationType: data.memberAffiliation || null
    },
    address: {
      addr_line_1: data.address1 || null,
      addr_line_2: data.address2 || null,
      unit: data.apartmentUnit || null,
      city: data.city || null,
      country: data.country || 'CAN',
      email_address: data.email || null,
      phone_area_code: getPhoneAndAreaCodeFromString(data.homePhone).areaCode,
      phone_no: getPhoneAndAreaCodeFromString(data.homePhone).number,
      mobilePhoneAreaCode: getPhoneAndAreaCodeFromString(data.mobilePhone)
        .areaCode,
      mobilePhoneNo: getPhoneAndAreaCodeFromString(data.mobilePhone).number,
      postal_code: data.postal || null,
      province: data.province || null,
      validated: isValidAddress ? 'Y' : 'N',
      paperlessConsentInd: data.paperlessConsent || null
    },
    notes: data.noteToOmers?.length > 0 ? data.noteToOmers : null
  };
};

/**
 * Generate create/update form 102 request model
 */
export const buildCreateUpdateRequestForm102Model = (
  selectEmployerNumber: number,
  requestNumber: string,
  username: string,
  data: Form102Data,
  isValidAddress: boolean,
  isNFTEnabled: boolean
) => {
  const hireDate = isNFTEnabled
    ? convertDateMDYtoYMD(data.hireDate)
    : getDateFromYMDStrings(
        data.hireDateYear,
        data.hireDateMonth,
        data.hireDateDay
      );
  let enrolmentDate: string | null = '';
  if (data.enrolSameAsHire) {
    enrolmentDate = hireDate;
  } else {
    enrolmentDate = isNFTEnabled
      ? convertDateMDYtoYMD(data.enrolDate)
      : getDateFromYMDStrings(
          data.enrolDateYear,
          data.enrolDateMonth,
          data.enrolDateDay
        );
  }
  let prevPPHireDate: string | null = '';
  if (data.hasPriorPension === 'Y') {
    if (isNFTEnabled) {
      prevPPHireDate = convertDateMDYtoYMD(data.priorPensionDate);
    } else {
      prevPPHireDate = getDateFromYMDStrings(
        data.priorPensionDateYear,
        data.priorPensionDateMonth,
        data.priorPensionDateDay
      );
    }
  }
  return {
    employerNo: selectEmployerNumber.toString(),
    requestNo: requestNumber ? parseInt(requestNumber, 10) : null,
    userId: username,
    processInDonnaInd: 'N',
    employeeEmployment: {
      requestNumber: requestNumber ? parseInt(requestNumber, 10) : null,
      updateUserid: username,
      birthDate: getDateFromYMDStrings(
        data.birthDateYear,
        data.birthDateMonth,
        data.birthDateDay
      ),
      employeeId: data.employeeId || null,
      employmentStatus: data.employmentStatus,
      enrolmentDate,
      firstName: data.firstName.trim(),
      hireDate,
      electionDate: convertDateMDYtoYMD(data.electionDate),
      hireEnrolDatesSameInd:
        data.enrolSameAsHire || isEqual(data.hireDate, data.enrolDate)
          ? 'Y'
          : 'N',
      languageCode: 'E',
      lastName: data.lastName.trim(),
      middleName: data.middleName.trim() || null,
      nra: data.normalRetirementAge || null,
      previousEmployer: null,
      previousTerminationDate: null,
      sex: data.sex,
      sin: data.sin ? data.sin.replace(/ /g, '') : null,
      departmentId: data.departmentID || null,
      annualSalaryRate:
        data.salary === 'A'
          ? formatCurrencyStringToNumber(data.salaryRate)
          : null,
      hourlyRate:
        data.salary === 'H'
          ? formatCurrencyStringToNumber(data.salaryRate)
          : null,
      prevPPParticipationInd: data.hasPriorPension,
      prevPPName: data.hasPriorPension === 'Y' ? data.priorPensionName : null,
      prevPPHireDate,
      pssOccupation: data.occupation,
      pssAffiliation: data.memberAffiliation,
      pssEnrolmentReason: data.enrolReason || null,
      releaseInfoConsentInd: data.signedForm132 || null,
      restructInd: data.hasDivestment,
      occupationCode: data.occupation || null
    },
    address: {
      addrLine1: data.address1 || null,
      addrLine2: data.address2 || null,
      aptSuiteUnitNumber: data.apartmentUnit || null,
      city: data.city || null,
      country: data.country || 'CAN',
      emailAddress: data.email || null,
      phoneAreaCode: getPhoneAndAreaCodeFromString(data.homePhone).areaCode,
      phoneNo: getPhoneAndAreaCodeFromString(data.homePhone).number,
      mobilePhoneAreaCode: getPhoneAndAreaCodeFromString(data.mobilePhone)
        .areaCode,
      mobilePhoneNo: getPhoneAndAreaCodeFromString(data.mobilePhone).number,
      postalCode: data.postal || null,
      province: data.province || null,
      validatedInd: isValidAddress ? 'Y' : 'N',
      paperlessConsentInd: data.paperlessConsent || null
    },
    notes: data.noteToOmers?.length > 0 ? data.noteToOmers : null
  };
};
