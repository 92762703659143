import { Theme as ThemeUI } from 'theme-ui';
// Cannot alias the below import to camelcase because of a storybook bug
// https://github.com/storybookjs/storybook/issues/20824
/* eslint-disable-next-line camelcase */
import { Roboto_Flex } from 'next/font/google';

const makeTheme = <T extends ThemeUI>(t: T) => t;

// Fonts from @next/font
export const RobotoFlex = Roboto_Flex({
  style: ['normal'],
  subsets: ['latin']
});

export const CONTAINER_MAX_WIDTH = '1500px';

export const BREAKPOINTS = {
  xsmall: 0,
  small: 768,
  smedium: 925,
  medium: 1111,
  large: 1536,
  xlarge: 1920
};

export const theme = makeTheme({
  fonts: {
    body: `${RobotoFlex.style.fontFamily}, sans-serif`,
    heading: `${RobotoFlex.style.fontFamily}, sans-serif`,
    monospace: 'Menlo, monospace'
  },
  fontSizes: {
    caption: '0.875rem',
    base: '1rem',
    small: '1.125rem',
    medium: '1.375rem',
    large: '1.75rem',
    xlMobile: '2rem',
    xl: '2.375rem',
    button: '1.1875rem'
  },
  fontWeights: {
    body: 400,
    medium: 500,
    semibold: 600,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    caption: '1.3125rem',
    base: '1.5rem',
    small: '1.625rem',
    body: '1.6875rem',
    medium: '2.0625rem',
    large: '2.625rem',
    xlMobile: '3rem',
    xl: '3.5625rem'
  },
  breakpoints: [
    `@media screen and (max-width: ${BREAKPOINTS.small - 1}px)`,
    `@media screen and (min-width: ${
      BREAKPOINTS.small
    }px) and (max-width: ${BREAKPOINTS.medium - 1}px)`,
    `@media screen and (min-width: ${
      BREAKPOINTS.medium
    }px) and (max-width: ${BREAKPOINTS.large - 1}px)`,
    `@media screen and (min-width: ${
      BREAKPOINTS.large
    }px) and (max-width: ${BREAKPOINTS.xlarge - 1}px)`,
    `@media screen and (min-width: ${BREAKPOINTS.xlarge}px)`
  ],
  colors: {
    // Primary Colors:
    brandOrangeWeb: '#d36816',
    brandOrange100: '#ee7623',
    brandOrange75: '#f1914f',
    brandOrange50: '#f7bb91',
    brandOrange25: '#fad6bd',
    brandNavy100: '#003a70',
    brandNavy75: '#1a4e7e',
    brandNavy50: '#6689a9',
    brandNavy25: '#b3c4d4',

    // States/Extended Colors
    extendedBlue100: '#0078b5',
    extendedBlue75: '#58b4ed',
    extendedBlue50: '#d7e9f2',
    extendedBlue25: '#f2f8fc',
    extendedBlueNav: '#55789B',

    extendedGreen100: '#0f9960',
    extendedGreen50: '#d9eee5',
    extendedGreen25: '#ebf7f2',
    extendedDashboardGreen: '#74c142',

    extendedYellow100: '#F0950C',
    extendedYellow50: '#FCEACE',
    extendedYellow25: '#FFF7E7',

    extendedOrange100: '#b15006',
    extendedOrange50: '#fceace',
    extendedOrange25: '#fff7e7',

    extendedRed100: '#db3737',
    extendedRed50: '#fae1e1',
    extendedRed25: '#fef2f2',

    // Neutral Colors
    black: '#000',
    darkGreyText: '#212529',
    grey100: '#707372',
    grey90: '#979797',
    grey80: '#c4c4c4',
    grey60: '#ccc',
    grey40: '#e5e5e5',
    grey20: '#f3f4f5',
    grey10: '#fbfbfb',
    white: '#fff'
  },
  styles: {
    caption: {
      fontFamily: 'body',
      fontSize: 'caption',
      lineHeight: 'caption',
      fontWeight: 'body'
    },
    body: {
      fontFamily: 'body',
      fontSize: 'small',
      lineHeight: 'base',
      fontWeight: 'body'
    },
    p: {
      fontFamily: 'body',
      fontSize: 'small',
      lineHeight: 'body',
      fontWeight: 'body',
      color: 'darkGreyText',
      backgroundColor: 'inherit'
    },
    h6: {
      fontFamily: 'heading',
      fontSize: 'caption',
      lineHeight: 'caption',
      fontWeight: 'heading'
    },
    h5: {
      fontFamily: 'heading',
      fontSize: 'base',
      lineHeight: 'base',
      fontWeight: 'heading'
    },
    h4: {
      fontFamily: 'heading',
      fontSize: 'small',
      lineHeight: 'small',
      fontWeight: 'heading'
    },
    h3: {
      fontFamily: 'heading',
      fontSize: 'medium',
      lineHeight: 'medium',
      fontWeight: 'heading'
    },
    h2: {
      fontFamily: 'heading',
      fontSize: 'large',
      lineHeight: 'large',
      fontWeight: 'heading',
      color: 'brandNavy100'
    },
    h1: {
      fontFamily: 'heading',
      fontSize: ['xl', 'xlMobile'],
      lineHeight: ['xl', 'xlMobile'],
      fontWeight: 'heading',
      color: 'brandNavy100'
    }
  },
  visuallyHidden: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    whiteSpace: 'nowrap',
    clipPath: 'inset(50%)',
    clip: ' rect(00 0 0)',
    overflow: 'hidden'
  }
});

export type ExactTheme = typeof theme;
