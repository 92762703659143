import * as yup from 'yup';
import { TFunction } from 'i18next';
import { parse, isValid, getYear, isEqual, isAfter, isBefore } from 'date-fns';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { yupResolver } = require('@hookform/resolvers/yup');

type YupTest = (this: yup.TestContext, value?: any) => boolean;
type YupValidationTuple = [string, string, YupTest];
const isRequiredOnStep = (
  errorMessage: string,
  stepNumber: number
): YupValidationTuple => [
  'is required',
  errorMessage,
  function(value) {
    return this.parent.stepNumber === stepNumber.toString() ? !!value : true;
  }
];

export const isOnOrAfterJanFirst2026 = (date: Date) =>
  isAfter(date, new Date(2026, 0, 1)) || isEqual(date, new Date(2026, 0, 1));

const leaveEndDateSchema = (t: TFunction) =>
  yup
    .string()
    .test(
      'is date valid',
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LEAVE_ENDED_ERROR_INVALID'
      ),
      value => (value ? isValid(parse(value, 'MM/dd/yyyy', new Date())) : true)
    )
    .test(
      'is year greater than 1900',
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LEAVE_ENDED_ERROR_GREATER_THAN_1900'
      ),
      value =>
        value ? getYear(parse(value, 'MM/dd/yyyy', new Date())) >= 1900 : true
    )
    .test(
      'is date greater than or equal to Date Leave Started',
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LEAVE_ENDED_ERROR_GREATER_THAN_STARTED'
      ),
      function(value) {
        const dateStarted = parse(
          this.parent.dateLeaveStarted,
          'MM/dd/yyyy',
          new Date()
        );
        const dateEnded = parse(value, 'MM/dd/yyyy', new Date());
        const startDateInvalid = !isValid(dateStarted);
        return value
          ? startDateInvalid ||
              isEqual(dateEnded, dateStarted) ||
              isAfter(dateEnded, dateStarted)
          : true;
      }
    );

const getToday = (isEnabled: boolean, dbSysDate: string) =>
  isEnabled && dbSysDate !== '' ? new Date(dbSysDate) : new Date();

export const form165aSchema = (
  dbSysDate: string,
  isEnabled: boolean,
  t: TFunction,
  isLeavePeriodReportingEnabled = false
) => ({
  scatteredDays: yup.string().required(),
  dateLeaveStarted: yup
    .string()
    .required(
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LEAVE_STARTED_ERROR_REQUIRED'
      )
    )
    .test(
      'is date valid',
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LEAVE_STARTED_ERROR_INVALID'
      ),
      value => isValid(parse(value, 'MM/dd/yyyy', new Date()))
    )
    .test(
      'is year greater than 1900',
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LEAVE_STARTED_ERROR_GREATER_THAN_1900'
      ),
      value => getYear(parse(value, 'MM/dd/yyyy', new Date())) >= 1900
    )
    .test(
      'is within the last 3 years',
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LEAVE_STARTED_ERROR_IS_WITHIN_LAST_3_YEARS'
      ),
      value => {
        const today = getToday(isEnabled, dbSysDate);
        return (
          getYear(today) - getYear(parse(value, 'MM/dd/yyyy', new Date())) <= 3
        );
      }
    )
    .test(
      "is date less than or equal to today's date",
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LEAVE_STARTED_ERROR_IS_AFTER_TODAY'
      ),
      value => {
        const today = getToday(isEnabled, dbSysDate);
        return isBefore(parse(value, 'MM/dd/yyyy', new Date()), today);
      }
    ),
  dateLeaveEnded: isLeavePeriodReportingEnabled
    ? leaveEndDateSchema(t).test(
        'is not required if leave start date is after Jan 1 2026',
        t(
          'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LEAVE_ENDED_ERROR_REQUIRED'
        ),
        function(value) {
          if (
            isOnOrAfterJanFirst2026(
              parse(this.parent.dateLeaveStarted, 'MM/dd/yyyy', new Date())
            )
          ) {
            return true;
          }
          return value;
        }
      )
    : leaveEndDateSchema(t).required(
        t(
          'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LEAVE_ENDED_ERROR_REQUIRED'
        )
      ),
  yearOfLeave: yup
    .string()
    .required(
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_YEAR_OF_LEAVE_ERROR_REQUIRED'
      )
    ),
  typeOfLeaveCategory: yup
    .string()
    .required(
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_TYPE_OF_LEAVE_ERROR_REQUIRED'
      )
    ),
  typeOfLeave: yup
    .string()
    .test(
      'required when typeOfLeaveCategory has value',
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_TYPE_OF_LEAVE_ERROR_REQUIRED'
      ),
      function(value) {
        if (this.parent.typeOfLeaveCategory) {
          if (value) {
            return true;
          }
          return false;
        }
        return true;
      }
    )
    .test(
      'is Disability Leave and has leave periods',
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_TYPE_OF_LEAVE_ERROR_DISABILITY'
      ),
      function(value) {
        return (
          value !== 'EL' ||
          (value === 'EL' &&
            (parseInt(this.parent.leavePeriodCount, 10) === 0 ||
              (parseInt(this.parent.leavePeriodCount, 10) === 1 &&
                this.parent.activeIntervalNumber)))
        );
      }
    ),
  isOpenEndedLeave: yup.string().required(),
  dateLastAtWork: yup
    .string()
    .required(
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LAST_AT_WORK_ERROR_REQUIRED'
      )
    )
    .test(
      'is date valid',
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LAST_AT_WORK_ERROR_INVALID'
      ),
      value => isValid(parse(value, 'MM/dd/yyyy', new Date()))
    )
    .test(
      'is year greater than 1900',
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LAST_AT_WORK_ERROR_GREATER_THAN_1900'
      ),
      value => getYear(parse(value, 'MM/dd/yyyy', new Date())) >= 1900
    )
    .test(
      "is date less than or equal to today's date",
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_DATE_LAST_AT_WORK_ERROR_BEFORE_TODAY'
      ),
      value => {
        const dateLastAtWork = parse(value, 'MM/dd/yyyy', new Date());
        const today = getToday(isEnabled, dbSysDate);
        return (
          isEqual(dateLastAtWork, today) || isBefore(dateLastAtWork, today)
        );
      }
    ),
  dateEntitledDisability: yup.string(),
  payPeriodsInLeaveYear: yup
    .string()
    .required(
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_HOW_MANY_PAY_PERIODS_ERROR_REQUIRED'
      )
    ),
  customPayPeriodsInLeaveYear: yup
    .string()
    .test(
      ...isRequiredOnStep(
        t(
          'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_HOW_MANY_PAY_PERIODS_ERROR_REQUIRED'
        ),
        2
      )
    )
    .test(
      'is range between 1 and 54',
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_HOW_MANY_PAY_PERIODS_ERROR_RANGE'
      ),
      value => parseInt(value, 10) >= 1 && parseInt(value, 10) <= 54
    ),
  contributoryEarningsPriorToLeave: yup
    .string()
    .required(
      t(
        'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_CONTRIBUTORY_EARNINGS_ERROR_REQUIRED'
      )
    ),
  purchaseCostList: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        creditedServiceMonths: yup
          .string()
          .test(
            ...isRequiredOnStep(
              t(
                'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_ADD_TABLE_CREDITED_SERVICE_ERROR_REQUIRED'
              ),
              2
            )
          )
          .test(
            'is scattered days range between 0 and 1',
            t('validations:VALIDATION_MUST_BE_BETWEEN', {
              field1: t(
                'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_ADD_TABLE_CREDITED_SERVICE_SCATTERED_DAYS'
              ),
              field2: '0',
              field3: '1'
            }),
            function(value) {
              const { isScatteredDays } = this.parent;
              const parsedVal = parseFloat(value);
              return isScatteredDays === 'Y'
                ? parsedVal > 0 && parsedVal < 1
                : true;
            }
          )
          .test(
            'is non-scattered days range between 0 and 12',
            t('validations:VALIDATION_MUST_BE_BETWEEN', {
              field1: t(
                'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_ADD_TABLE_CREDITED_SERVICE_NON_SCATTERED_DAYS'
              ),
              field2: '0',
              field3: '12.00'
            }),
            function(value) {
              const { isScatteredDays } = this.parent;
              const parsedVal = parseFloat(value);
              return isScatteredDays === 'N'
                ? parsedVal > 0 && parsedVal <= 12
                : true;
            }
          )
          .test(
            'is has 2 or less decimal places',
            t('validations:VALIDATION_MUST_HAVE_2_OR_LESS_DECIMALS', {
              field: t(
                'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_ADD_TABLE_CREDITED_SERVICE'
              )
            }),
            value => (value?.split('.')[1]?.length ?? 0) <= 2
          )
          .test(
            'is valid value',
            t('validations:VALIDATION_MUST_BE_VALID', {
              field: t(
                'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_ADD_TABLE_CREDITED_SERVICE'
              )
            }),
            value => /^\d*(\.\d{1,2})?$/g.test(value)
          ),
        contributoryEarnings: yup
          .string()
          .test(
            ...isRequiredOnStep(
              t(
                'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_ADD_TABLE_CONTRIBUTORY_EARNINGS_ERROR_REQUIRED'
              ),
              2
            )
          ),
        paidFollowingYear: yup.string(),
        rppContributions: yup
          .string()
          .test(
            ...isRequiredOnStep(
              t(
                'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_ADD_TABLE_RPP_CONTRIBUTIONS_ERROR_REQUIRED'
              ),
              3
            )
          ),
        rcaContributions: yup
          .string()
          .test(
            ...isRequiredOnStep(
              t(
                'form165a:REQUEST_FORM_LEAVE_PERIOD_EMPLOYER_ADD_TABLE_RCA_CONTRIBUTIONS_ERROR_REQUIRED'
              ),
              3
            )
          )
      })
    ),
  noteToMember: yup.string(),
  noteToOmers: yup.string(),
  ...(isLeavePeriodReportingEnabled && {
    scatteredDaysList: yup
      .array()
      .test(
        'has at least one date ',
        t('form165a:SCATTERED_DAYS_REQUIRED'),
        function(value) {
          if (this.parent.scatteredDays === 'N') {
            return true;
          }
          return value && value.length > 0;
        }
      ),
    percentageTimeWorked: yup
      .string()
      .required(t('form165:PERCENTAGE_OF_TIME_MANDATORY'))
      .test(
        'between 0 and 100',
        t('form165:PERCENTAGE_OF_TIME_WORKED_RANGE_ERROR'),
        value => Number(value) >= 0 && Number(value) <= 100
      ),
    periodNotWorked: yup.object().shape({
      from: yup
        .string()
        .nullable()
        .test({
          name: 'both from and to must be present',
          message: t('form165:PERIOD_NOT_WORKED_INVALID') as string,
          test(val) {
            const { to } = this.parent;
            return (to && val) || (!to && !val);
          }
        }),
      to: yup
        .string()
        .nullable()
        .test({
          name: 'both from and to must be present',
          message: t('form165:PERIOD_NOT_WORKED_INVALID') as string,
          test(val) {
            const { from } = this.parent;
            return (from && val) || (!from && !val);
          }
        })
    })
  })
});

const form165aValidations = (
  dbSysDate: string,
  isEnabled: boolean,
  t: TFunction,
  isLeavePeriodReportingEnabled?: boolean
) =>
  yupResolver(
    yup
      .object()
      .shape(
        form165aSchema(dbSysDate, isEnabled, t, isLeavePeriodReportingEnabled)
      )
  );

export default form165aValidations;
