import { SelectMenuItem } from 'components/v2/atomic/selectInput/SelectInput';
import { postData } from './services';

export const getStaffMembers = (groupNumber: string) =>
  postData(
    '/api/microsoft-bookings/staff-members',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    { 'X-Group-No': groupNumber }
  );

export const getAppointments = (groupNumber: string, email: string) =>
  postData(
    '/api/microsoft-bookings/list-appointments',
    {
      email
    },
    undefined,
    undefined,
    undefined,
    undefined,
    { 'X-Group-No': groupNumber }
  );

export const getStaffAvailability = (
  groupNumber: string,
  params: {
    staffMember: Array<SelectMenuItem> | SelectMenuItem;
    day: string;
    timeInterval: number | undefined;
  }
) =>
  postData(
    '/api/microsoft-bookings/staff-availability',
    {
      params
    },
    undefined,
    undefined,
    undefined,
    undefined,
    { 'X-Group-No': groupNumber }
  );

export const createAppointment = (
  groupNumber: string,
  payload: {
    staff: string[];
    day: string | undefined;
    time: string | undefined;
    request: string;
    duration: string | undefined;
    email: string;
    name: string;
  }
) =>
  postData(
    '/api/microsoft-bookings/create-appointment',
    {
      payload
    },
    undefined,
    undefined,
    undefined,
    undefined,
    { 'X-Group-No': groupNumber }
  );

export const updateAppointment = (
  groupNumber: string,
  payload: {
    appointmentId: string;
    staff: string[];
    day: string | undefined;
    time: string | undefined;
    request: string;
    duration: string | undefined;
  }
) =>
  postData(
    '/api/microsoft-bookings/update-appointment',
    {
      payload
    },
    undefined,
    undefined,
    undefined,
    undefined,
    { 'X-Group-No': groupNumber }
  );

export const cancelAppointment = (groupNumber: string, appointmentId: string) =>
  postData(
    '/api/microsoft-bookings/cancel-appointment',
    {
      appointmentId
    },
    undefined,
    undefined,
    undefined,
    undefined,
    { 'X-Group-No': groupNumber }
  );
