/* eslint-disable camelcase */
import { Form102DataModel } from 'interfaces';
import { maskCurrency, getYMDStringsFromDate } from 'utils';
import { convertDateYMDtoMDY } from 'utils/date';

export const mapForm102V1 = (form: any) => ({
  // Employee Information section
  sin: form.e102Model.employeeEmployment?.sin?.toString() ?? '',
  employeeId: form.e102Model.employeeEmployment?.employeeId ?? '',
  employerNo: form.e102Model.employerNo ?? '',
  departmentID: form.e102Model.employeeEmployment?.departmentId ?? '',
  firstName: form.e102Model.employeeEmployment?.firstName ?? '',
  middleName: form.e102Model.employeeEmployment?.middleName ?? '',
  lastName: form.e102Model.employeeEmployment?.lastName ?? '',
  sex: form.e102Model.employeeEmployment?.sex ?? '',
  birthDateMonth:
    getYMDStringsFromDate(form.e102Model.employeeEmployment?.birthDate).month ??
    '',
  birthDateDay: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.birthDate
  ).day,
  birthDateYear: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.birthDate
  ).year,
  signedForm132:
    form.e102Model.employeeEmployment?.releaseInfoConsentInd ?? 'N',

  // Employment section
  occupation: form.e102Model.employeeEmployment?.pssOccupation ?? '',
  memberAffiliation: form.e102Model.employeeEmployment?.pssAffiliation ?? '',
  normalRetirementAge: form.e102Model.employeeEmployment?.nra ?? '',
  hireDateMonth:
    getYMDStringsFromDate(form.e102Model.employeeEmployment?.hireDate).month ??
    '',
  hireDateDay: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.hireDate
  ).day,
  hireDateYear: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.hireDate
  ).year,
  hireDate: convertDateYMDtoMDY(form.e102Model.employeeEmployment?.hireDate),
  electionDate: convertDateYMDtoMDY(
    form.e102Model.employeeEmployment?.electionDate
  ),
  enrolSameAsHire:
    form.e102Model.employeeEmployment?.hireEnrolDatesSameInd === 'Y',
  enrolDateMonth:
    getYMDStringsFromDate(form.e102Model.employeeEmployment?.enrolmentDate)
      .month ?? '',
  enrolDateDay: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.enrolmentDate
  ).day,
  enrolDateYear: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.enrolmentDate
  ).year,
  enrolDate: convertDateYMDtoMDY(
    form.e102Model.employeeEmployment?.enrolmentDate
  ),
  enrolReason: form.e102Model.employeeEmployment?.enrolReason ?? '',

  // Compensation section
  salary: form.e102Model.employeeEmployment?.hourlyRate ? 'H' : 'A',
  salaryRate: (() => {
    if (form.e102Model.employeeEmployment?.hourlyRate) {
      return form.e102Model.employeeEmployment?.hourlyRate
        ? maskCurrency(
            form.e102Model.employeeEmployment.hourlyRate
              ?.toFixed(2)
              .toString() ?? '',
            'en'
          )
        : '';
    }
    return form.e102Model.employeeEmployment?.annualSalaryRate
      ? maskCurrency(
          form.e102Model.employeeEmployment.annualSalaryRate
            ?.toFixed(2)
            .toString() ?? '',
          'en'
        )
      : '';
  })(),
  employmentStatus: form.e102Model.employeeEmployment?.employmentStatus ?? 'C',
  hasDivestment: form.e102Model.employeeEmployment?.restructInd ?? 'N',
  hasPriorPension:
    form.e102Model.employeeEmployment?.prevPPParticipationInd ?? 'N',
  priorPensionDateMonth:
    getYMDStringsFromDate(form.e102Model.employeeEmployment?.prevPPHireDate)
      .month ?? '',
  priorPensionDateDay: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.prevPPHireDate
  ).day,
  priorPensionDateYear: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.prevPPHireDate
  ).year,
  priorPensionDate: convertDateYMDtoMDY(
    form.e102Model.employeeEmployment?.prevPPHireDate
  ),
  priorPensionName: form.e102Model.employeeEmployment?.prevPPName ?? '',

  // Address section
  apartmentUnit: form.e102Model.address?.unit ?? '',
  address1: form.e102Model.address?.addr_line_1 ?? '',
  address2: form.e102Model.address?.addr_line_2 ?? '',
  country: form.e102Model.address?.country ?? 'CAN',
  province: form.e102Model.address?.province ?? '',
  city: form.e102Model.address?.city ?? '',
  postal: form.e102Model.address?.postal_code ?? '',
  mobilePhone: `${form.e102Model.address?.mobilePhoneAreaCode ?? ''}${form
    .e102Model.address?.mobilePhoneNo ?? ''}`,
  homePhone: `${form.e102Model.address?.phone_area_code ?? ''}${form.e102Model
    .address?.phone_no ?? ''}`,
  email: form.e102Model.address?.email_address,
  paperlessConsent: form.e102Model.address?.paperlessConsentInd ?? 'N',
  noteToOmers: form.e102Model.notes ?? ''
});

const mapForm102 = (form: Form102DataModel) => ({
  // Employee Information section
  sin: form.employeeEmployment?.sin?.toString() ?? '',
  employeeId: form.employeeEmployment?.employeeId ?? '',
  employerNo: form.employerNo ?? '',
  departmentID: form.employeeEmployment?.departmentId ?? '',
  firstName: form.employeeEmployment?.firstName ?? '',
  middleName: form.employeeEmployment?.middleName ?? '',
  lastName: form.employeeEmployment?.lastName ?? '',
  sex: form.employeeEmployment?.sex ?? '',
  birthDateMonth:
    getYMDStringsFromDate(form.employeeEmployment?.birthDate).month ?? '',
  birthDateDay: getYMDStringsFromDate(form.employeeEmployment?.birthDate).day,
  birthDateYear: getYMDStringsFromDate(form.employeeEmployment?.birthDate).year,
  signedForm132: form.employeeEmployment?.releaseInfoConsentInd ?? 'N',

  // Employment section
  occupation: form.employeeEmployment?.pssOccupation ?? '',
  memberAffiliation: form.employeeEmployment?.pssAffiliation ?? '',
  normalRetirementAge: form.employeeEmployment?.nra ?? '',
  hireDateMonth:
    getYMDStringsFromDate(form.employeeEmployment?.hireDate).month ?? '',
  hireDateDay: getYMDStringsFromDate(form.employeeEmployment?.hireDate).day,
  hireDateYear: getYMDStringsFromDate(form.employeeEmployment?.hireDate).year,
  hireDate: form.employeeEmployment?.hireDate
    ? convertDateYMDtoMDY(form.employeeEmployment.hireDate)
    : '',
  electionDate: form.employeeEmployment?.electionDate
    ? convertDateYMDtoMDY(form.employeeEmployment.electionDate)
    : '',
  enrolSameAsHire: form.employeeEmployment?.hireEnrolDatesSameInd === 'Y',
  enrolDateMonth:
    getYMDStringsFromDate(form.employeeEmployment?.enrolmentDate).month ?? '',
  enrolDateDay: getYMDStringsFromDate(form.employeeEmployment?.enrolmentDate)
    .day,
  enrolDateYear: getYMDStringsFromDate(form.employeeEmployment?.enrolmentDate)
    .year,
  enrolDate: form.employeeEmployment?.enrolmentDate
    ? convertDateYMDtoMDY(form.employeeEmployment.enrolmentDate)
    : '',
  enrolReason: form.employeeEmployment?.enrolReason ?? '',

  // Compensation section
  salary: form.employeeEmployment?.hourlyRate ? 'H' : 'A',
  salaryRate: (() => {
    const rate =
      form.employeeEmployment?.hourlyRate ||
      form.employeeEmployment?.annualSalaryRate;
    return rate ? maskCurrency(rate.toFixed(2).toString(), 'en') : '';
  })(),
  employmentStatus: form.employeeEmployment?.employmentStatus ?? 'C',
  hasDivestment: form.employeeEmployment?.restructInd ?? 'N',
  hasPriorPension: form.employeeEmployment?.prevPPParticipationInd ?? 'N',
  priorPensionDateMonth:
    getYMDStringsFromDate(form.employeeEmployment?.prevPPHireDate).month ?? '',
  priorPensionDateDay: getYMDStringsFromDate(
    form.employeeEmployment?.prevPPHireDate
  ).day,
  priorPensionDateYear: getYMDStringsFromDate(
    form.employeeEmployment?.prevPPHireDate
  ).year,
  priorPensionDate: form.employeeEmployment?.prevPPHireDate
    ? convertDateYMDtoMDY(form.employeeEmployment.prevPPHireDate)
    : '',
  priorPensionName: form.employeeEmployment?.prevPPName ?? '',

  // Address section
  apartmentUnit: form.address?.aptSuiteUnitNumber ?? '',
  address1: form.address?.addrLine1 ?? '',
  address2: form.address?.addrLine2 ?? '',
  country: form.address?.country ?? 'CAN',
  province: form.address?.province ?? '',
  city: form.address?.city ?? '',
  postal: form.address?.postalCode ?? '',
  mobilePhone: `${form.address?.mobilePhoneAreaCode ?? ''}${form.address
    ?.mobilePhoneNo ?? ''}`,
  homePhone: `${form.address?.phoneAreaCode ?? ''}${form.address?.phoneNo ??
    ''}`,
  email: form.address?.emailAddress,
  paperlessConsent: form.address?.paperlessConsentInd ?? 'N',
  noteToOmers: form.notes ?? ''
});

export default mapForm102;
