import { all, put, takeLeading } from 'redux-saga/effects';
import clientSide from 'utils/logger/client-side';
import { overrideReasonsActionTypes } from 'containers/overrideReasons/overrideReasonsActions';
import { getDonnaBusinessConfig } from './donnaBusinessConfigReducer';

// Might be used for future donna business configs
// eslint-disable-next-line no-empty-function
export function* getDonnaBusinessConfigSaga() {
  try {
    yield put({
      type: overrideReasonsActionTypes.OVERRIDE_REASONS_REQUESTED
    });
  } catch (e) {
    clientSide.error((e as Error).message, {}, e as Error);
  }
}

export function* donnaBusinessConfigSaga() {
  yield all([
    takeLeading(getDonnaBusinessConfig.type, getDonnaBusinessConfigSaga)
  ]);
}
