/* eslint-disable camelcase */
import {
  all,
  takeLatest,
  takeLeading,
  put,
  select,
  call,
  delay
} from 'redux-saga/effects';
import Router from 'next/router';
import { format } from 'url';

import { EapAction, RootState } from 'types';
import { safeAuthEffect, safeNavHistoryBack } from 'store/sagas/sagaUtils';
import {
  createForm102,
  getForm102,
  submitForm102,
  updateForm102,
  getPDF,
  validatePhoneNumber,
  getForm102V1,
  submitForm102V1,
  createForm102V1,
  updateForm102V1
} from 'services';
import {
  viewPdf,
  downloadPdf,
  getApiErrorMessage,
  featureFlagSelector,
  getErrorMessageWithParams
} from 'utils';
import {
  lockFormSaga,
  unlockFormSaga,
  deleteFormSaga,
  clearLockedFormNotificationSaga
} from 'containers/requestForm/common/sagas/formSagaUtils';
import {
  clearNotifications,
  notifyErrorAction,
  notifyLockedFormErrorAction
} from 'containers/notifications/notificationsActions';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import { responseTypes } from 'types/response-type';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import { QASEmailResponseTypes } from 'types/qas-email-response-types';
import {
  form102ActionTypes,
  createForm102SuccessAction,
  createForm102FailedAction,
  deleteForm102SuccessAction,
  deleteForm102FailedAction,
  setForm102StepAction,
  saveForm102SuccessAction,
  saveForm102FailedAction,
  getForm102SuccessAction,
  getForm102FailedAction,
  setForm102SubmitStatusAction,
  submitForm102Action,
  submitForm102SuccessAction,
  setForm102SavingStatusAction,
  unlockForm102Action,
  setPhoneNumberValidityStatus
} from './form102Actions';
import {
  buildCreateUpdateRequestForm102Model,
  buildCreateUpdateRequestForm102ModelV1
} from './utils/form102Utils';
import { initialState } from './form102Reducer';
import mapForm102, { mapForm102V1 } from './mapForm102';

export function* getForm102SagaV1(action: EapAction): any {
  yield put(startLoadingAction());

  const requestNumber = action.data;

  yield call(lockFormSaga, { ...action, data: requestNumber });

  // fetch 102 form
  const res = yield call(safeAuthEffect, getForm102V1, requestNumber);
  if (res.data) {
    yield put(
      getForm102SuccessAction({
        employerNo: res.data?.e102Model?.employerNo,
        requestNumber,
        form102Data: mapForm102V1(res.data),
        exceptions: res.data?.e102Model?.exceptions ?? [],
        validAddressState:
          res.data?.e102Model?.address?.validated === 'Y'
            ? 'valid'
            : 'not-validated',
        validAddress:
          res.data?.e102Model?.address?.validated === 'Y'
            ? {
                address1: res.data?.e102Model?.address?.addr_line_1 ?? '',
                country: res.data?.e102Model?.address?.country ?? '',
                province: res.data?.e102Model?.address?.province ?? '',
                city: res.data?.e102Model?.address?.city ?? '',
                postal: res.data?.e102Model?.address?.postal_code ?? ''
              }
            : initialState.validAddress,
        validEmailAddressState:
          res.data?.e102Model?.email?.validated === 'Y'
            ? QASEmailResponseTypes.VERIFIED
            : QASEmailResponseTypes.NOT_VERIFIED,
        validEmailAddress:
          res.data?.e102Model?.email?.validated === 'Y'
            ? {
                email: res.data?.e102Model?.email ?? ''
              }
            : initialState.validEmailAddress
      })
    );

    // route user to form if not already there
    if (Router.route !== '/form/102/[id]') {
      yield call(
        Router.push,
        '/form/102/[id]',
        format({ pathname: `/form/102/${requestNumber}` })
      );
    }
  } else {
    yield put(getForm102FailedAction());
    yield put(notifyLockedFormErrorAction(getApiErrorMessage(res.errors)));

    // navigate off the request if user is on the page from a direct link
    if (Router.route === '/form/102/[id]') {
      yield call(safeNavHistoryBack, '/requests');
    }
  }

  yield put(clearLoadingAction());
  yield call(clearLockedFormNotificationSaga);
}

export function* getForm102Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const requestNumber = action.data;

  yield call(lockFormSaga, { ...action, data: requestNumber });

  // fetch 102 form
  const res = yield call(safeAuthEffect, getForm102, requestNumber);
  if (res?.result?.data) {
    yield put(
      getForm102SuccessAction({
        employerNo: res.result.data?.employerNo,
        requestNumber,
        form102Data: mapForm102(res.result.data),
        exceptions: res.result.data?.exceptions ?? [],
        validAddressState:
          res.result.data?.address?.validatedInd === 'Y'
            ? 'valid'
            : 'not-validated',
        validAddress:
          res.result.data?.address?.validatedInd === 'Y'
            ? {
                address1: res.result.data?.address?.addrLine1 ?? '',
                country: res.result.data?.address?.country ?? '',
                province: res.result.data?.address?.province ?? '',
                city: res.result.data?.address?.city ?? '',
                postal: res.result.data?.address?.postalCode ?? ''
              }
            : initialState.validAddress
      })
    );

    // route user to form if not already there
    if (Router.route !== '/form/102/[id]') {
      yield call(
        Router.push,
        '/form/102/[id]',
        format({ pathname: `/form/102/${requestNumber}` })
      );
    }
  } else {
    yield put(getForm102FailedAction());
    if (res?.errors?.length) {
      yield put(
        notifyLockedFormErrorAction(getErrorMessageWithParams(res.errors[0]))
      );
    }

    // navigate off the request if user is on the page from a direct link
    if (Router.route === '/form/102/[id]') {
      yield call(safeNavHistoryBack, '/requests');
    }
  }

  yield put(clearLoadingAction());
  yield call(clearLockedFormNotificationSaga);
}

export function* saveForm102SagaV1(action: EapAction): any {
  const { requestNumber, validAddressState } = yield select(
    (state: RootState) => state.form102
  );
  const { selectEmployerNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const { username } = yield select(
    (state: RootState) => state.userSession.userData
  );
  const { featureFlags } = yield select(featureFlagSelector);

  // create new form if not active requestNumber, otherwise save
  if (!requestNumber) {
    const res = yield call(
      safeAuthEffect,
      createForm102V1,
      buildCreateUpdateRequestForm102ModelV1(
        selectEmployerNumber,
        requestNumber,
        username,
        action.data.formData,
        validAddressState === 'valid',
        isEnabled(featureFlags, currentFlags.NFT)
      )
    );
    if (res.data) {
      yield put(
        createForm102SuccessAction({
          requestNumber: res.data.requestNumber,
          employerNo: selectEmployerNumber,
          formData: action.data.formData
        })
      );

      // route user to newly created form
      if (!action.data.preventNav) {
        yield call(
          Router.push,
          '/form/102/[id]',
          format({ pathname: `/form/102/${res.data.requestNumber}` })
        );
      }

      yield put(clearNotifications());

      // navigate to step 2 on a successfully newly created form and successful get request
      if (action.data.reviewForm) {
        yield put(setForm102StepAction(2));
      }
    } else {
      yield put(createForm102FailedAction());
      yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    }
  } else {
    const res = yield call(
      safeAuthEffect,
      updateForm102V1,
      {
        ...buildCreateUpdateRequestForm102ModelV1(
          selectEmployerNumber,
          requestNumber,
          username,
          action.data.formData,
          validAddressState === 'valid',
          isEnabled(featureFlags, currentFlags.NFT)
        ),
        exceptions: action.data.exceptions
      },
      requestNumber
    );

    if (res.data) {
      yield put(saveForm102SuccessAction(action.data.formData));

      if (action.data.reviewForm) {
        yield put(setForm102StepAction(2));
      }

      if (action.data.submitForm) {
        yield put(submitForm102Action());
      }

      // unlock form on modal dialog exit
      if (action.data.lockForm) {
        yield put(unlockForm102Action());
      }

      yield put(clearNotifications());
    } else {
      yield put(saveForm102FailedAction());
      yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    }
  }

  yield delay(3000);
  yield put(setForm102SavingStatusAction(''));
}

export function* saveForm102Saga(action: EapAction): any {
  const { requestNumber, validAddressState } = yield select(
    (state: RootState) => state.form102
  );
  const { selectEmployerNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const { username } = yield select(
    (state: RootState) => state.userSession.userData
  );
  const { featureFlags } = yield select(featureFlagSelector);

  // create new form if not active requestNumber, otherwise save
  if (!requestNumber) {
    const res = yield call(
      safeAuthEffect,
      createForm102,
      buildCreateUpdateRequestForm102Model(
        selectEmployerNumber,
        requestNumber,
        username,
        action.data.formData,
        validAddressState === 'valid',
        isEnabled(featureFlags, currentFlags.NFT)
      )
    );
    if (res?.result?.data) {
      yield put(
        createForm102SuccessAction({
          requestNumber: res.result.data,
          employerNo: 0,
          formData: action.data.formData
        })
      );

      // route user to newly created form
      if (!action.data.preventNav) {
        yield call(
          Router.push,
          '/form/102/[id]',
          format({ pathname: `/form/102/${res.result.data}` })
        );
      }

      yield put(clearNotifications());

      // navigate to step 2 on a successfully newly created form and successful get request
      if (action.data.reviewForm) {
        yield put(setForm102StepAction(2));
      }
    } else {
      yield put(createForm102FailedAction());
      if (res?.errors?.length) {
        yield put(notifyErrorAction(getErrorMessageWithParams(res.errors[0])));
      }
    }
  } else {
    const res = yield call(
      safeAuthEffect,
      updateForm102,
      {
        ...buildCreateUpdateRequestForm102Model(
          selectEmployerNumber,
          requestNumber,
          username,
          action.data.formData,
          validAddressState === 'valid',
          isEnabled(featureFlags, currentFlags.NFT)
        ),
        exceptions: action.data.exceptions
      },
      requestNumber
    );

    if (res?.result?.data) {
      yield put(saveForm102SuccessAction(action.data.formData));

      if (action.data.reviewForm) {
        yield put(setForm102StepAction(2));
      }

      if (action.data.submitForm) {
        yield put(submitForm102Action());
      }

      // unlock form on modal dialog exit
      if (action.data.lockForm) {
        yield put(unlockForm102Action());
      }

      yield put(clearNotifications());
    } else {
      yield put(saveForm102FailedAction());
      if (res?.errors?.length) {
        yield put(notifyErrorAction(getErrorMessageWithParams(res.errors[0])));
      }
    }
  }

  yield delay(3000);
  yield put(setForm102SavingStatusAction(''));
}

function* deleteForm102Saga(action: EapAction) {
  if (action.data) {
    const { requestNumber } = yield select((state: RootState) => state.form102);

    yield call(
      deleteFormSaga,
      {
        ...action,
        data: requestNumber
      },
      {
        deleteSuccessAction: deleteForm102SuccessAction(),
        deleteFailedAction: deleteForm102FailedAction()
      }
    );
  } else {
    // navigate away from non-saved form 102 instead of deleting
    yield call(safeNavHistoryBack, '/requests');
  }
}

export function* unlockForm102ActionSaga(action: EapAction) {
  const { requestNumber, submissionComplete } = yield select(
    (state: RootState) => state.form102
  );

  // prevent unnecessary unlock if form successfully submitted
  if (!submissionComplete) {
    yield call(unlockFormSaga, {
      ...action,
      data: requestNumber
    });
  }
}

function* getForm102PDFSaga(action: EapAction): any {
  const { isView } = action.data;
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, getPDF, action.data);
  yield put(clearLoadingAction());

  if (
    res?.type === responseTypes.OCTET_STREAM ||
    res?.type === responseTypes.PDF
  ) {
    if (isView) {
      viewPdf(res, 'e-Form 102: Member Enrolment');
    } else {
      downloadPdf(res, 'e-Form 102: Member Enrolment');
    }
    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* submitForm102SagaV1(): any {
  yield put(startLoadingAction());
  yield put(setForm102SubmitStatusAction('submitting'));

  const { requestNumber } = yield select((state: RootState) => state.form102);

  const res = yield call(safeAuthEffect, submitForm102V1, requestNumber);

  if (res?.data?.submitResponse) {
    yield put(clearNotifications());
    yield put(submitForm102SuccessAction(res.data.submitResponse));
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(setForm102SubmitStatusAction('error'));
  }

  yield put(clearLoadingAction());
  yield delay(3000);
  yield put(setForm102SubmitStatusAction(''));
}

export function* submitForm102Saga(): any {
  yield put(startLoadingAction());
  yield put(setForm102SubmitStatusAction('submitting'));

  const { requestNumber } = yield select((state: RootState) => state.form102);

  const res = yield call(safeAuthEffect, submitForm102, requestNumber);

  if (res?.result?.data) {
    yield put(clearNotifications());
    yield put(submitForm102SuccessAction(res.result.data));
  } else {
    if (res?.errors?.length) {
      yield put(notifyErrorAction(getErrorMessageWithParams(res.errors[0])));
    }
    yield put(setForm102SubmitStatusAction('error'));
  }

  yield put(clearLoadingAction());
  yield delay(3000);
  yield put(setForm102SubmitStatusAction(''));
}

function* setForm102StepSaga(action: EapAction) {
  // clear API error switching back from step 2 to 1
  if (action.data === 1) {
    yield put(clearNotifications());
  }
}

export function* validatePhoneNumberSaga(action: EapAction): any {
  const { requestNumber } = yield select((state: RootState) => state.form102);
  const { phoneNumber, phoneNumberType } = action.data;

  const res = yield call(
    safeAuthEffect,
    validatePhoneNumber,
    { number: phoneNumber },
    requestNumber
  );
  if (res.result) {
    let phoneNumberValidityStatus = '';
    switch (res.result.confidence) {
      case 'Verified':
      case 'Absent':
        phoneNumberValidityStatus = 'Valid';
        break;
      case 'Unverified':
      case 'Dead':
        phoneNumberValidityStatus = 'Invalid';
        break;
      default:
        phoneNumberValidityStatus = 'Unknown';
        break;
    }
    yield put(
      setPhoneNumberValidityStatus({
        phoneNumberType,
        phoneNumberValidity: phoneNumberValidityStatus
      })
    );
  } else {
    yield put(
      setPhoneNumberValidityStatus({
        phoneNumberType,
        phoneNumberValidity: 'Unknown'
      })
    );
  }
}

export function* form102Saga(): any {
  yield all([
    yield takeLatest(form102ActionTypes.GET_FORM_102, function*(
      action: EapAction
    ) {
      const { featureFlags } = yield select(featureFlagSelector);
      const sagaImplementation = isEnabled(
        featureFlags,
        currentFlags.V2_MEMBER_ENROLMENT_APIS
      )
        ? getForm102Saga
        : getForm102SagaV1;

      yield call(sagaImplementation, action);
    }),
    yield takeLeading(form102ActionTypes.SAVE_FORM_102, function*(
      action: EapAction
    ) {
      const { featureFlags } = yield select(featureFlagSelector);
      const sagaImplementation = isEnabled(
        featureFlags,
        currentFlags.V2_MEMBER_ENROLMENT_APIS
      )
        ? saveForm102Saga
        : saveForm102SagaV1;

      yield call(sagaImplementation, action);
    }),
    yield takeLeading(form102ActionTypes.DELETE_FORM_102, deleteForm102Saga),
    yield takeLatest(form102ActionTypes.GET_FORM_102_PDF, getForm102PDFSaga),
    yield takeLeading(
      form102ActionTypes.UNLOCK_FORM_102,
      unlockForm102ActionSaga
    ),
    yield takeLatest(form102ActionTypes.SUBMIT_FORM_102, function*() {
      const { featureFlags } = yield select(featureFlagSelector);
      if (isEnabled(featureFlags, currentFlags.V2_MEMBER_ENROLMENT_APIS)) {
        yield call(submitForm102Saga);
      } else {
        yield call(submitForm102SagaV1);
      }
    }),
    yield takeLatest(form102ActionTypes.SET_FORM_102_STEP, setForm102StepSaga),
    yield takeLatest(
      form102ActionTypes.VALIDATE_PHONE_NUMBER_REQUESTED,
      validatePhoneNumberSaga
    )
  ]);
}
