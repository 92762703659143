import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { RootState, Attachment } from 'types';
import { MemberSearchResult } from 'interfaces';
import { selectEmployersSelector } from 'utils';
import Button from 'components/button/Button';
import ContainerBorder from 'components/containerBorder/ContainerBorder';
import Divider from 'components/divider/Divider';
import useModal from 'components/modal/useModal';
import { isEnabled, currentFlags } from 'features/featureFlags/featureFlags';
import NewRequestModal, { FORM_LINKS } from 'components/modal/NewRequestModal';
import { getForm102PDFAction } from 'containers/requestForm/memberEnrolment/form102Actions';
import { getForm106PDFAction } from 'containers/requestForm/memberUpdate/form106Actions';
import { getForm143PDFAction } from 'containers/requestForm/memberEvent/form143Actions';
import {
  getForm165aPDFAction,
  getForm165aFrenchPDFAction
} from 'containers/requestForm/leavePeriodEmployer/form165aActions';

import { getForm165bPDFAction } from 'containers/requestForm/leavePeriodMember/form165bActions';
import { openNewMessageWithPresetDataAction } from 'containers/messages/messagesActions';
import SelectRemittanceTypeModal from 'components/modal/selectRemittanceTypeModal/SelectRemittanceTypeModal';
import Notification from 'components/notification/Notification';
import { useRouter } from 'next/router';
import { getPdfAction } from './submitLandingPageActions';
import styles from './SubmitLandingPage.module.scss';

const cx = classNames.bind(styles);

interface SubmitLandingPageProps {
  form: string;
  requestNumber: string | null;
  memberInfo?: MemberSearchResult | null;
  reconciliationYear?: string;
  hasPdf?: boolean;
  form143EventType?: string;
  form143Type?: string;
  pdfs?: Array<Attachment>;
  nft?: boolean;
  resetFormNFT?: () => void;
}
const SubmitLandingPage = ({
  form,
  requestNumber,
  memberInfo,
  reconciliationYear,
  hasPdf = true,
  form143EventType,
  form143Type,
  pdfs = [],
  nft = false,
  resetFormNFT
}: SubmitLandingPageProps) => {
  const dispatch = useDispatch();
  const Router = useRouter();
  const { t } = useTranslation(['forms', 'form119']);
  const { selectEmployerNumber } = useSelector(selectEmployersSelector);
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );

  const { has11998Or11999Warnings } = useSelector(
    (state: RootState) => state?.form119 || false
  );
  const advancedElectionPdf = pdfs?.find(pdf => pdf.formNo === '_AOP');
  const containMemberElectionForm =
    pdfs?.filter(pdf => pdf.formNo === '165_ele').length > 0;

  const [
    isVisibleSelectRemittanceTypeModal,
    setIsVisibleSelectRemittanceTypeModal
  ] = useState(false);

  const isFrenchFormsEnabled = isEnabled(
    featureFlags,
    currentFlags.FORM_165A_165C_FRENCH
  );

  // TODO: Deprecate this, migrate pdf downloads to generic getPdfAction
  const viewOrDownload = (isView: boolean, alternativePdf = false) => {
    const data = {
      requestNumber,
      formNo: form,
      employerNo: selectEmployerNumber,
      isView
    };

    switch (form) {
      case '102':
        dispatch(getForm102PDFAction(data));
        break;
      case '105':
        dispatch(
          getPdfAction({
            ...data,
            fileName: 'e-Form 105 - Contribution Remittance Summary'
          })
        );
        break;
      case '106':
        dispatch(getForm106PDFAction(data));
        break;
      case '143':
        dispatch(
          getForm143PDFAction({
            ...data,
            fileName: t('REQUEST_FORM_SUBMITTED_PDF_HEADING_143', {
              form143Type,
              memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
            })
          })
        );
        break;
      case '165a': {
        if (!alternativePdf) {
          dispatch(getForm165aPDFAction(data));
        } else {
          dispatch(
            getForm165aPDFAction({
              ...data,
              formNo: '165_ele'
            })
          );
        }
        break;
      }
      case '165b': {
        if (!alternativePdf) {
          dispatch(getForm165bPDFAction(data));
        } else {
          dispatch(
            getForm165bPDFAction({
              ...data,
              formNo: '165_ele'
            })
          );
        }
        break;
      }
      case '165c': {
        if (!alternativePdf) {
          dispatch(
            getPdfAction({
              ...data,
              fileName: 'e-Form 165c: Leave Period Reporting (Adjustment)'
            })
          );
        } else {
          dispatch(
            getPdfAction({
              ...data,
              formNo: '165_ele',
              fileName: 'Member Election Form'
            })
          );
        }
        break;
      }
      case '190':
        dispatch(
          getPdfAction({
            ...data,
            fileName: 'e-Form 190 - Pension Estimate'
          })
        );
        break;
    }
  };

  const viewOrDownloadFrench = (isView: boolean, alternativePdf = false) => {
    const data = {
      requestNumber,
      isView
    };

    if (!alternativePdf) {
      dispatch(getForm165aFrenchPDFAction(data));
    } else {
      dispatch(
        getForm165aFrenchPDFAction({
          ...data,
          formNo: '165_ele'
        })
      );
    }
  };

  const [isShowing, setIsShowing] = useModal();

  const itemList: [{ formNumber: string; formName: string; callback?: any }] = [
    FORM_LINKS.form102
  ];

  if (isEnabled(featureFlags, currentFlags.FORM_105)) {
    itemList.push({
      ...FORM_LINKS.form105,
      callback: () => setIsVisibleSelectRemittanceTypeModal(true)
    });
  }

  itemList.push(FORM_LINKS.form106);

  if (isEnabled(featureFlags, currentFlags.FORM_119)) {
    itemList.push(FORM_LINKS.form119);
  }

  if (isEnabled(featureFlags, currentFlags.FORM_143)) {
    itemList.push(FORM_LINKS.form143);
  }

  if (isEnabled(featureFlags, currentFlags.FORM_143_DEATH)) {
    itemList.push(FORM_LINKS.form143death);
  }

  if (isEnabled(featureFlags, currentFlags.FORM_143_DISABILITY)) {
    itemList.push(FORM_LINKS.form143disability);
  }

  if (isEnabled(featureFlags, currentFlags.FORM_152)) {
    itemList.push(FORM_LINKS.form152);
  }

  if (isEnabled(featureFlags, currentFlags.FORM_158_PRD)) {
    itemList.push(FORM_LINKS.form158prd);
    itemList.push(FORM_LINKS.form158rtw);
  }

  if (isEnabled(featureFlags, currentFlags.FORM_165A)) {
    itemList.push(FORM_LINKS.form165a);
  }

  if (isEnabled(featureFlags, currentFlags.FORM_165B)) {
    itemList.push(FORM_LINKS.form165b);
  }

  if (isEnabled(featureFlags, currentFlags.FORM_165C)) {
    itemList.push(FORM_LINKS.form165c);
  }

  if (isEnabled(featureFlags, currentFlags.FORM_190)) {
    itemList.push(FORM_LINKS.form190);
  }

  const renderSubmissionTitle = () => {
    switch (form) {
      case '102':
        return t('REQUEST_FORM_SUBMITTED_SUBTEXT_2_102');
      case '105':
        return t('REQUEST_FORM_SUBMITTED_SUBTEXT_2_105');
      case '106':
        return t('REQUEST_FORM_SUBMITTED_SUBTEXT_2_106', {
          memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
        });
      case '119':
        return t('REQUEST_FORM_SUBMITTED_SUBTEXT_2_119', {
          reconciliationYear: `${reconciliationYear}`
        });
      case '143':
        return t('REQUEST_FORM_SUBMITTED_SUBTEXT_2_143', {
          form143Type,
          memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
        });
      case '165a':
        return t('REQUEST_FORM_SUBMITTED_SUBTEXT_2_165A', {
          memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
        });
      case '165b':
        return t('REQUEST_FORM_SUBMITTED_SUBTEXT_2_165B', {
          memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
        });
      case '165c':
        return t('REQUEST_FORM_SUBMITTED_SUBTEXT_2_165C', {
          memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
        });
      case '190':
        return t('REQUEST_FORM_SUBMITTED_SUBTEXT_2_190');
    }

    return '';
  };

  const renderFormTitle = () => {
    switch (form) {
      case '102':
        return t('REQUEST_FORM_SUBMITTED_PDF_HEADING_102');
      case '105':
        return t('REQUEST_FORM_SUBMITTED_PDF_HEADING_105');
      case '106':
        return t('REQUEST_FORM_SUBMITTED_PDF_HEADING_106', {
          memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
        });
      case '119':
        return t('REQUEST_FORM_SUBMITTED_PDF_HEADING_119', {
          memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
        });
      case '143':
        return t('REQUEST_FORM_SUBMITTED_PDF_HEADING_143', {
          form143Type,
          memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
        });
      case '165a':
        return t('REQUEST_FORM_SUBMITTED_PDF_HEADING_165A', {
          memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
        });
      case '165b':
        return t('REQUEST_FORM_SUBMITTED_PDF_HEADING_165B', {
          memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
        });
      case '165c':
        return t('REQUEST_FORM_SUBMITTED_PDF_HEADING_165C', {
          memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
        });
      case '190':
        return t('REQUEST_FORM_SUBMITTED_PDF_HEADING_190');
    }

    return '';
  };

  return (
    <>
      <NewRequestModal
        visible={isShowing}
        onCancel={() => setIsShowing()}
        titleText={t('REQUESTS_NEW_REQUEST_MODAL_HEADER')}
        itemList={itemList}
      />

      <SelectRemittanceTypeModal
        visible={isVisibleSelectRemittanceTypeModal}
        onClose={() => setIsVisibleSelectRemittanceTypeModal(false)}
      />

      <div
        className={cx({
          submitLandingPage: true,
          submitLandingPageWithNewNav: isEnabled(
            featureFlags,
            currentFlags.NEW_NAV_HEADER
          )
        })}
      >
        {has11998Or11999Warnings && form === '119' && (
          <Notification
            type="info"
            messages={[
              <>
                <span className="semiBold">
                  {t('form119:ANNUAL_REPORTING_WARNING_11999_OR_11998_1')}
                </span>
                <span className={styles.noSubmitLandingPageFontWeight}>
                  {t('form119:ANNUAL_REPORTING_WARNING_11999_OR_11998_2', {
                    name: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
                  })}
                </span>
                <Button
                  baseStyle="link"
                  disableSvgStroke
                  onClick={() =>
                    dispatch(
                      openNewMessageWithPresetDataAction({
                        topic: '119',
                        linkedMember: memberInfo,
                        hasAttachment: true,
                        attachmentCategory: 'Annual Reporting',
                        attachmentDocumentType: 'Pay Period Breakdown'
                      })
                    )
                  }
                >
                  <span className={styles.noSubmitLandingPageFontWeight}>
                    {t('form119:ANNUAL_REPORTING_WARNING_11999_OR_11998_3')}
                  </span>
                </Button>
                <span className={styles.noSubmitLandingPageFontWeight}>
                  {t('form119:ANNUAL_REPORTING_WARNING_11999_OR_11998_4')}
                </span>
              </>
            ]}
          />
        )}
        <ContainerBorder>
          <div className={styles.topSection}>
            <h2>{t('REQUEST_FORM_SUBMITTED_HEADING')}</h2>
            <div>
              {nft
                ? t('REQUEST_FORM_SUBMITTED_SUBTEXT_NFT')
                : t('REQUEST_FORM_SUBMITTED_SUBTEXT_1')}
              {renderSubmissionTitle()}
            </div>
            <div>
              {nft
                ? t('REQUEST_FORM_SUBMITTED_SUBTEXT_NFT_1')
                : t('REQUEST_FORM_SUBMITTED_SUBTEXT_3')}
            </div>
            {hasPdf && (
              <div className={styles.pdfDiv}>
                <ReactSVG src="/images/pdf-icon.svg" />
                <h3>{renderFormTitle()}</h3>
                <div className={styles.viewDownload}>
                  {isFrenchFormsEnabled && (
                    <p sx={{ pr: '1.3125rem', mb: '0' }}>
                      {t('REQUEST_FORM_SUBMITTED_ENGLISH')}
                    </p>
                  )}
                  <Button
                    onClick={() => {
                      viewOrDownload(true);
                    }}
                    baseStyle="link"
                    testingTag="submit-view-form-pdf-btn"
                  >
                    {t('REQUEST_FORM_SUBMITTED_VIEW_PDF')}
                  </Button>

                  <span className={styles.pipe}>|</span>

                  <Button
                    onClick={() => {
                      viewOrDownload(false);
                    }}
                    baseStyle="link"
                    testingTag="submit-dl-form-pdf-btn"
                  >
                    {t('REQUEST_FORM_SUBMITTED_DOWNLOAD_PDF')}
                  </Button>
                </div>
              </div>
            )}

            {(form === '165a' || form === '165b' || form === '165c') &&
              containMemberElectionForm && (
                <div className={styles.pdfDiv}>
                  <ReactSVG src="/images/pdf-icon.svg" />
                  {form === '165a' && (
                    <h3>
                      {t('REQUEST_FORM_SUBMITTED_PDF_HEADING_165A_ELECTION', {
                        memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
                      })}
                    </h3>
                  )}
                  {form === '165b' && (
                    <h3>
                      {t('REQUEST_FORM_SUBMITTED_PDF_HEADING_165B_ELECTION', {
                        memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
                      })}
                    </h3>
                  )}
                  {form === '165c' && (
                    <h3>
                      {t('REQUEST_FORM_SUBMITTED_PDF_HEADING_165C_ELECTION', {
                        memberName: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
                      })}
                    </h3>
                  )}
                  <div>
                    <div className={styles.viewDownload}>
                      {isFrenchFormsEnabled && (
                        <p sx={{ pr: '1.3125rem', mb: '0' }}>
                          {t('REQUEST_FORM_SUBMITTED_ENGLISH')}
                        </p>
                      )}
                      <Button
                        onClick={() => viewOrDownload(true, true)}
                        baseStyle="link"
                        testingTag="submit-view-election-pdf-btn"
                      >
                        {t('REQUEST_FORM_SUBMITTED_VIEW_PDF')}
                      </Button>

                      <span className={styles.pipe}>|</span>

                      <Button
                        onClick={() => viewOrDownload(false, true)}
                        baseStyle="link"
                        testingTag="submit-dl-election-pdf-btn"
                      >
                        {t('REQUEST_FORM_SUBMITTED_DOWNLOAD_PDF')}
                      </Button>
                    </div>
                    {isFrenchFormsEnabled && (
                      <div className={styles.viewDownload}>
                        <p sx={{ pr: '1.3125rem', mb: '0' }}>
                          {t('REQUEST_FORM_SUBMITTED_FRENCH')}
                        </p>
                        <Button
                          onClick={() => viewOrDownloadFrench(true, true)}
                          baseStyle="link"
                          testingTag="submit-view-election-pdf-btn-french"
                        >
                          {t('REQUEST_FORM_SUBMITTED_VIEW_PDF')}
                        </Button>

                        <span className={styles.pipe}>|</span>

                        <Button
                          onClick={() => viewOrDownloadFrench(false, true)}
                          baseStyle="link"
                          testingTag="submit-dl-election-pdf-btn-french"
                        >
                          {t('REQUEST_FORM_SUBMITTED_DOWNLOAD_PDF')}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {advancedElectionPdf && form143EventType !== 'R' && (
              <div className={styles.pdfDiv}>
                <ReactSVG src="/images/pdf-icon.svg" />
                <h3>{t('REQUEST_FORM_ADVANCED_ELECTION_PDF')}</h3>

                <div className={styles.viewDownload}>
                  <Button
                    onClick={() =>
                      dispatch(
                        getPdfAction({
                          requestNumber,
                          formNo: advancedElectionPdf.formNo,
                          fileName: advancedElectionPdf.formType,
                          employerNo: selectEmployerNumber,
                          isView: true
                        })
                      )
                    }
                    baseStyle="link"
                    testingTag="submit-view-advanced-election-pdf-btn"
                  >
                    {t('REQUEST_FORM_SUBMITTED_VIEW_PDF')}
                  </Button>

                  <span className={styles.pipe}>|</span>

                  <Button
                    onClick={() =>
                      dispatch(
                        getPdfAction({
                          requestNumber,
                          formNo: advancedElectionPdf.formNo,
                          fileName: advancedElectionPdf.formType,
                          employerNo: selectEmployerNumber,
                          isView: false
                        })
                      )
                    }
                    baseStyle="link"
                    testingTag="submit-dl-advanced-election-pdf-btn"
                  >
                    {t('REQUEST_FORM_SUBMITTED_DOWNLOAD_PDF')}
                  </Button>
                </div>

                <div className={styles.advancedElectionDesc}>
                  <span>{t('REQUEST_FORM_ADVANCED_ELECTION_DESC')} </span>
                  <Button
                    baseStyle="link"
                    onClick={() =>
                      dispatch(
                        openNewMessageWithPresetDataAction({
                          topic:
                            form143EventType === 'T'
                              ? '143-termination'
                              : '143-retirement',
                          subject:
                            form143EventType === 'T'
                              ? t('form143:143_NEW_MESSAGE_SUBJECT_TERMINATION')
                              : t('form143:143_NEW_MESSAGE_SUBJECT_RETIREMENT'),
                          linkedMember: memberInfo
                        })
                      )
                    }
                  >
                    {t('REQUEST_FORM_ADVANCED_ELECTION_LINK')}
                  </Button>
                  .
                </div>
              </div>
            )}
          </div>

          {hasPdf && <Divider />}

          <div className={cx({ bottomSection: true, noPDFSpacing: !hasPdf })}>
            <Button
              onClick={() => {
                if (nft && resetFormNFT) {
                  resetFormNFT();
                } else {
                  setIsShowing();
                }
              }}
            >
              {t('REQUEST_FORM_SUBMITTED_NEW_REQUEST_BTN')}
            </Button>
          </div>

          {nft && (
            <div className={cx({ bottomSection: true, nftBack: true })}>
              <Button
                onClick={() => Router.push(`/nft`)}
                baseStyle="outlineOrange"
              >
                {t('REQUEST_FORM_SUBMITTED_BACK_TO_LIST_BTN')}
              </Button>
            </div>
          )}
        </ContainerBorder>
      </div>
    </>
  );
};

export default SubmitLandingPage;
