import { ChangeEvent, FocusEvent } from 'react';
import classNames from 'classnames/bind';
import { Controller, Control } from 'react-hook-form-legacy';

import Tooltip from 'components/v2/atomic/tooltip/Tooltip';
import styles from './CheckBoxInput.module.scss';

interface CheckBoxInputProps {
  name?: string;
  title: string;
  checked?: boolean;
  defaultValue?: boolean;
  control?: Control;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  autoTestingTag?: string;
  isError?: boolean;
  altStyle?: boolean;
  refRegister?: (instance: HTMLInputElement) => void;
  noTopMargin?: boolean;
  noLeftPadding?: boolean;
  disableCheckboxText?: boolean;
  titleWithMargin?: boolean;
  altBorder?: boolean;
  tooltip?: string | JSX.Element | Array<string | JSX.Element>;
  override?: boolean;
}

const CheckBoxInput = ({
  title,
  name,
  checked,
  defaultValue,
  control,
  onChange,
  disabled,
  autoTestingTag,
  onBlur,
  isError,
  refRegister,
  noTopMargin,
  noLeftPadding,
  disableCheckboxText,
  altStyle,
  altBorder,
  titleWithMargin,
  tooltip,
  override = false
}: CheckBoxInputProps) => {
  const cx = classNames.bind(styles);
  const checkboxClassNames = cx({
    isFieldError: isError,
    hiddenCheckbox: true,
    noTop: noTopMargin || false
  });
  const labelText = cx({
    checkboxLabel: true,
    disabledCheckboxText: !!disabled || disableCheckboxText
  });
  const checkboxIconClassNames = cx({
    checkboxIcon: true,
    noTop: noTopMargin || false,
    alt: altStyle,
    altBorder
  });
  const titleClass = cx({
    labelTop: override,
    titleWithMargin
  });
  if (control) {
    return (
      <Controller
        name={name || title}
        control={control}
        render={() => (
          <div
            className={cx({
              checkboxContainer: true,
              noLeftPadding
            })}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className={labelText}>
              <input
                name={name || title}
                type="checkbox"
                defaultChecked={defaultValue}
                className={checkboxClassNames}
                onChange={onChange}
                onBlur={onBlur}
                checked={checked}
                disabled={disabled}
                data-auto={autoTestingTag}
                data-testid={autoTestingTag}
              />
              <span className={checkboxIconClassNames} />
              <span className={titleClass}>{title}</span>
            </label>
          </div>
        )}
      />
    );
  }
  return (
    <div className={styles.checkbox}>
      <div
        className={cx({
          checkboxContainer: true,
          noLeftPadding
        })}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={labelText}>
          <input
            name={name || title}
            type="checkbox"
            className={checkboxClassNames}
            defaultChecked={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            checked={checked}
            disabled={disabled}
            data-auto={autoTestingTag}
            data-testid={autoTestingTag}
            ref={refRegister}
          />
          <span className={checkboxIconClassNames} />
          <span className={titleClass}>{title}</span>
        </label>
      </div>

      {tooltip && <Tooltip content={tooltip} usePortal={false} />}
    </div>
  );
};

CheckBoxInput.defaultProps = {
  name: '',
  title: '',
  disabled: false,
  onChange: undefined,
  onBlur: undefined,
  autoTestingTag: '',
  isError: false,
  checked: false,
  noTopMargin: false,
  disableCheckboxText: false,
  altStyle: false,
  titleWithMargin: false,
  altBorder: false
};

export default CheckBoxInput;
