import { createSlice } from '@reduxjs/toolkit';

export interface DonnaBusinessConfigState {}

const initialState: DonnaBusinessConfigState = {};

const DonnaBusinessConfigSlice = createSlice({
  name: 'donnaBusinessConfig',
  initialState,
  reducers: {
    getDonnaBusinessConfig: () => {}
  }
});

export default DonnaBusinessConfigSlice.reducer;
export const { getDonnaBusinessConfig } = DonnaBusinessConfigSlice.actions;
