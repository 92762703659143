/* eslint-disable camelcase */
import { DataObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { isEmpty, isString } from 'lodash';
import clientSide from 'utils/logger/client-side';

const NEXT_PUBLIC_APP_URL = env('NEXT_PUBLIC_APP_URL');
const NEXT_PUBLIC_ADMIN_APP_URL = env('NEXT_PUBLIC_ADMIN_APP_URL');

export const submitForm101 = async (
  data: Record<string, any> & { groupNo: string },
  token: string,
  username: string,
  omersUser: boolean
) => {
  clientSide.info(
    `[ATTEMPT] Form 101 submit attempt for ${username} - ${data.groupNo}`,
    {
      ddAttr: 'nftSubmissionTracking',
      groupNumber: data.groupNo,
      username
    }
  );
  const res = await fetch(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/nft/v1/member`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        username,
        'X-Group-No': data.groupNo
      },
      body: new URLSearchParams({
        data: isString(data) ? data : JSON.stringify(data)
      })
    }
  );
  const response = await res?.json();
  if (response?.result?.success && isEmpty(response.result.errors)) {
    clientSide.info(
      `[SUCCESS] Form 101 submit for ${username} - ${data.groupNo}`,
      {
        ddAttr: 'nftSubmissionTracking',
        groupNumber: data.groupNo,
        username
      }
    );
  }
  return response;
};

export const updateForm101 = async (
  data: Record<string, any> & { groupNo: string },
  token: string,
  username: string,
  omersUser: boolean
) => {
  const res = await fetch(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/nft/v1/member`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        username,
        'X-Group-No': data.groupNo
      },
      body: new URLSearchParams({
        data: isString(data) ? data : JSON.stringify(data)
      })
    }
  );
  const response = await res?.json();
  return response;
};

export const getPaginationInformation = async (
  data: Record<string, any> & { groupNo: string },
  token: string,
  username: string,
  omersUser: boolean
) => {
  const res = await fetch(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/nft/v1/page-info`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        username,
        'X-Group-No': data.groupNo
      },
      body: new URLSearchParams({
        data: isString(data) ? data : JSON.stringify(data)
      })
    }
  );
  const response = await res?.json();
  return response;
};

export const loadMemberData = async (
  data: Record<string, any> & { groupNo: string },
  token: string,
  username: string,
  omersUser: boolean
) => {
  const res = await fetch(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/nft/v1/member-search`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        username,
        'X-Group-No': data.groupNo
      },
      body: new URLSearchParams({
        data: isString(data) ? data : JSON.stringify(data)
      })
    }
  );
  const response = await res?.json();
  return response;
};

export const getEmployeeInfo = async (
  nftId: number,
  groupNo: string,
  token: string,
  username: string,
  omersUser: boolean
) => {
  const res = await fetch(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/nft/v1/member?nftId=${nftId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        username,
        'X-Group-No': groupNo
      }
    }
  );
  const response = await res?.json();
  return response;
};

export const getOmersOffersNFT = async (
  groupNo: number,
  token: string,
  username: string,
  omersUser: boolean
) => {
  const res = await fetch(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/nft/v1/offer`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        username,
        'X-Group-No': groupNo.toString()
      }
    }
  );
  const response = await res?.json();
  return response;
};

export const fetchUploadedBatchNFTs = async (
  groupNo: number,
  token: string,
  username: string,
  omersUser: boolean
) => {
  const res = await fetch(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/nft/v1/batch/uploads`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        username,
        'X-Group-No': groupNo.toString()
      }
    }
  );
  const response = await res?.json();
  return response;
};

export const validateUploads = async (
  data: Array<DataObj>,
  selectGroupNumber: string,
  token: string,
  username: string,
  omersUser: boolean
) => {
  const res = await fetch(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/nft/v1/batch/validate`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        username,
        'X-Group-No': selectGroupNumber
      },
      body: new URLSearchParams({
        data: isString(data) ? data : JSON.stringify(data)
      })
    }
  );
  const response = await res?.json();
  return response;
};

export const submitUploads = async (
  data: Array<DataObj>,
  selectGroupNumber: string,
  token: string,
  username: string,
  omersUser: boolean
) => {
  const res = await fetch(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/nft/v1/batch/submit`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        username,
        'X-Group-No': selectGroupNumber
      },
      body: new URLSearchParams({
        data: isString(data) ? data : JSON.stringify(data)
      })
    }
  );
  const response = await res?.json();
  return response;
};

export const getFileBuilder = async (
  token: string,
  username: string,
  omersUser: boolean
) => {
  const res = await fetch(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/nft/v1/batch/download/import-file-builder`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        username
      }
    }
  );
  const response = await res?.blob();
  return response;
};
